import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseListResponse, EntityTypeFieldDto, ModuleKeywords, RequestHandlerOptions, TargetTypeEnum } from '@shared/classes';
import { BaseRequestControllerService } from 'app/shared/services/api/base-request-controller.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntityTypesDataService extends BaseRequestControllerService<EntityTypeFieldDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.entity,
      '/entity-types',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'label',
        'icon',
        'category',
        'ar-SY',
      ],
      ModuleKeywords.EntityType
    );
  }

  entityModuleByType = {
    [EntityTypeFieldDto.CategoryEnum.Event]: TargetTypeEnum.Event,
    [EntityTypeFieldDto.CategoryEnum.Asset]: TargetTypeEnum.Asset,
    [EntityTypeFieldDto.CategoryEnum.Geography]: TargetTypeEnum.Geography,
    [EntityTypeFieldDto.CategoryEnum.Objective]: TargetTypeEnum.Objective,
    [EntityTypeFieldDto.CategoryEnum.Process]: TargetTypeEnum.Process,
    [EntityTypeFieldDto.CategoryEnum.Responsibility]: TargetTypeEnum.Responsibility,
    [EntityTypeFieldDto.CategoryEnum.Organization]: TargetTypeEnum.Organization,
  };

  getDynamicFieldsContainsNotNull(typeCode, fieldCode, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseListResponse<String[]>>(
      `${this.url}/${typeCode}/dynamics-field-contains-notnull/${fieldCode}`,
      options
    ) as Observable<BaseListResponse<String[]>>;
  }
}
