import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse, ModuleKeywords, QuestionFieldsDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from 'app/shared/services/api/base-request-controller.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionsDataService extends BaseRequestControllerService<QuestionFieldsDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.questions,
      '/questions',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'question',
        'questionType',
        'options',
        'dynamicField',
        'name',
        'label',
        'required',
        'type',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
      ],
      ModuleKeywords.Question
    );
  }

  private _prepareFilters(data: any) {
    let filters = [];
    if (!!data?.searchTerm) {
      filters = [{ property: 'question', operation: 'CONTAINS', value: data.searchTerm }];
    }
    return filters;
  }

  public searchByQuestion(keywords) {
    return this.search(
      { page: 0, size: 20 },
      {
        projectionFields: [
          'id',
          'question',
          'code',
          'recordStatus',
          'translations',
          'originalLanguage',
          'fields',
          'fieldDtos',
          'options',
        ],
        filters: this._prepareFilters({ searchTerm: keywords }),
      }
    );
  }
  getByIdOrCode<T>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(this.url + '/getByIdOrCode/' + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
}
