/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseDisruptiveIncidentEvent { 
    status?: number;
    data?: ApiResponseDisruptiveIncidentEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseDisruptiveIncidentEvent {
    export type DataEnum = 'TO_CLOSED' | 'TO_ACTIVE';
    export const DataEnum = {
        Closed: 'TO_CLOSED' as DataEnum,
        Active: 'TO_ACTIVE' as DataEnum
    };
}


