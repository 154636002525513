import { Injectable } from '@angular/core';
import {
  DataTypeEnum,
  ExportPackageDto,
  IColumn,
  IDynamicComponent,
  IFieldDefinition,
  ImporterColumnDefinition,
  RecordStatusEnumFilterWithoutDeleted,
  TargetTypeEnum,
  getImporterColumnDefinitions,
  getTableColumnDefinitions,
  isNullObj,
} from '@shared/classes';

@Injectable({
  providedIn: 'root',
})
export class BaseFieldDefinitionsService {
  //@TODO override implementation needed here
  prefixBaseFields: IFieldDefinition[] = [
    {
      name: 'Id',
      key: 'code',
      dataType: DataTypeEnum.CodeWithStatus,
      required: false,
      showInTable: true,
      description: 'Unique identifier for the record',
      tableDefinition: {
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
    },
  ];
  suffixBaseFields: IFieldDefinition[] = [
    {
      name: 'Tags',
      key: 'tags',
      dataType: DataTypeEnum.TagSearch,
      required: false,
      showInTable: true,
      description: 'Tags of the record',
      tableDefinition: {
        filter: {
          type: 'tagsSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
        // frozen: true,
        alignFrozen: 'left',
      },
    },

    {
      name: 'Record Status',
      key: 'recordStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Current Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
    },
    {
      name: 'Creator Name',
      key: 'creatorName',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      description: 'The user who created this record.',
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Creation Date',
      key: 'creationDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Creation Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Last Modifier',
      key: 'lastModifierName',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      description: 'Last Modifier of the record',
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Last Modification Date',
      key: 'lastModificationDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Last Modification Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'ACL',
      key: 'acl',
      dataType: DataTypeEnum.AuditTrailAcl,
      required: false,
      showInTable: false,
      description: 'User Access Level of the record',
      tableDefinition: {},
    },
    {
      name: 'State',
      key: 'state',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      description: 'Status of the record',
      tableDefinition: {},
    },
  ];
  recordFields: IFieldDefinition[] = [];

  tableFields: IColumn[] = [];
  formFields: IDynamicComponent[] = [];
  importerFields: ImporterColumnDefinition[] = [];
  mappedFields: { [key: string]: IFieldDefinition } = {};

  constructor() { }
  getTableColumns(fields: IFieldDefinition[]): IColumn[] {
    return [
      ...getTableColumnDefinitions(this.prefixBaseFields),
      ...getTableColumnDefinitions(fields),
      ...getTableColumnDefinitions(this.suffixBaseFields),
    ];
  }
  getImporterColumns(fields: IFieldDefinition[]): ImporterColumnDefinition[] {
    return [
      //   ...getImporterColumnDefinitions(this.prefixBaseFields),
      ...getImporterColumnDefinitions(fields),
      //   ...getImporterColumnDefinitions(this.suffixBaseFields)
    ];
  }
  getFormFields(fields: IFieldDefinition[]) {
    return fields.map((field) => {
      return {
        ...field.formField,
        options: field?.formField?.options
          ? {
            ...field?.formField?.options,
            label: isNullObj(field.formField?.options?.label) ? field?.name : field.formField?.options?.label,
            name: isNullObj(field.formField?.options?.name) ? field?.key : field.formField?.options?.name,
          }
          : { label: field?.name },
      } as IDynamicComponent;
    });
  }
  getMappedFields(fields: IFieldDefinition[]): { [key: string]: IFieldDefinition } {
    let ret: { [key: string]: IFieldDefinition } = {};
    this.prefixBaseFields.forEach((field) => {
      ret[field.key] = field;
    });
    fields.forEach((field) => {
      ret[field.key] = field;
    });
    this.suffixBaseFields.forEach((field) => {
      ret[field.key] = field;
    });
    return ret;
  }
  setAllFields(fields: IFieldDefinition[] = this.recordFields) {
    this.tableFields = this.getTableColumns(fields);
    this.importerFields = this.getImporterColumns(fields);
    this.formFields = this.getFormFields(fields);
    this.mappedFields = this.getMappedFields(fields);
  }
}
export const PackageListColumns: IColumn[] = [
  {
    name: 'Id',
    key: 'code',
    dataType: DataTypeEnum.CodeWithStatus,
    passRowAsData: true,
    filter: {
      type: 'text',
      matchMode: 'startsWith',
    },
    // frozen: true,
    alignFrozen: 'left',
  },
  {
    name: 'Record Status',
    key: 'recordStatus',
    dataType: DataTypeEnum.Badge,
    filter: {
      type: 'enum',
      display: 'menu',
      matchMode: 'in',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
      enumClass: RecordStatusEnumFilterWithoutDeleted,
    },
  },
  {
    name: 'Name',
    key: 'name',
    dataType: DataTypeEnum.Text,
    filter: {
      type: 'text',
      display: 'menu',
      matchMode: 'startsWith',
      showMatchModes: true,
      showAddButton: true,
      showOperator: true,
    },
  },
  {
    name: 'File Id',
    key: 'fileId',
    dataType: DataTypeEnum.Link,
    filter: {
      type: 'text',
      display: 'menu',
      matchMode: 'startsWith',
      showMatchModes: true,
      showAddButton: true,
      showOperator: true,
    },
  },
  {
    name: 'Types',
    key: 'typesToInclude',
    dataType: DataTypeEnum.Badge,
    filter: {
      type: 'enum',
      display: 'menu',
      matchMode: 'in',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
      enumClass: TargetTypeEnum,
    },
    options: {
      multi: true,
    },
  },
  {
    name: 'Package Status',
    key: 'packageStatus',
    dataType: DataTypeEnum.Badge,
    filter: {
      type: 'enum',
      display: 'menu',
      matchMode: 'in',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
      enumClass: ExportPackageDto.PackageStatusEnum,
    },
  },
  {
    name: 'Creator Name',
    key: 'creatorName',
    dataType: DataTypeEnum.UserListView,
    filter: {
      type: 'userSelector',
      matchMode: 'in',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
    },
  },
  {
    name: 'Creation Date',
    key: 'creationDate',
    dataType: DataTypeEnum.DateLong,
    filter: {
      type: 'date',
      matchMode: 'dateBefore',
    },
  },
  {
    name: 'Last Modifier',
    key: 'lastModifierName',
    dataType: DataTypeEnum.UserListView,
    filter: {
      type: 'userSelector',
      matchMode: 'in',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
    },
  },
  {
    name: 'Last Modification Date',
    key: 'lastModificationDate',
    dataType: DataTypeEnum.DateLong,
    filter: {
      type: 'date',
      matchMode: 'dateBefore',
    },
  },
];
export const RelationFilterField: IFieldDefinition = {
  name: 'Relations',
  key: 'relationsFilter',
  dataType: DataTypeEnum.Text,
  required: false,
  showInTable: true,
  description: 'Relations Filter on the record',
  tableDefinition: {
    filter: {
      type: 'relation',
      display: 'menu',
      matchMode: 'equals',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
    },
  },
};
// counts
// relations
export const GeneratedPackageListColumns: IColumn[] = [
  {
    name: 'Id',
    key: 'code',
    dataType: DataTypeEnum.CodeWithStatus,
    passRowAsData: true,
    filter: {
      type: 'text',
      matchMode: 'startsWith',
    },
    // frozen: true,
    alignFrozen: 'left',
  },
  {
    name: 'Document',
    key: 'documentUrl',
    dataType: DataTypeEnum.CodeLink,
    filter: {
      type: 'text',
      display: 'menu',
      matchMode: 'startsWith',
      showMatchModes: true,
      showAddButton: true,
      showOperator: true,
    },
  },
  {
    name: 'Counts',
    key: 'counts',
    dataType: DataTypeEnum.TargetTypeCount,
    sortDisabled: true,
    filter: null,
    options: {
      multi: true,
    },
  },
  {
    name: 'Error Message',
    key: 'errorMessage',
    dataType: DataTypeEnum.Text,
    filter: {
      type: 'text',
      display: 'menu',
      matchMode: 'startsWith',
      showMatchModes: true,
      showAddButton: true,
      showOperator: true,
    },
  },
  {
    name: 'Creator Name',
    key: 'creatorName',
    dataType: DataTypeEnum.UserListView,
    filter: {
      type: 'userSelector',
      matchMode: 'in',
      showMatchModes: false,
      showAddButton: false,
      showOperator: false,
    },
  },
  {
    name: 'Creation Date',
    key: 'creationDate',
    dataType: DataTypeEnum.DateLong,
    filter: {
      type: 'date',
      matchMode: 'dateBefore',
    },
  },
];
