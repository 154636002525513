import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponseIssueEvent, BaseResponse, ModuleKeywords, RequestHandlerOptions } from '@shared/classes';
import { Issue } from '@shared/classes/model/backend/issue';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IssuesDataService extends BaseRequestControllerWithRuleService<Issue, ApiResponseIssueEvent.DataEnum> {
  private updateEscalationPathsUrl = '/update-escalation-paths';
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.issue,
      '/issues',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
        'assignee',
        'issueOwner',
        'issueTypeCode',
        'issueStatus',
        'criticality',
        'state',
        'parent',
        'sourceType',
        'sourceCode',
        'causeItems',
        'effectItems',
        'causeItemsTypes',
        'effectItemsTypes',
        'estimatedResolutionDate',
        'actualResolutionDate',
        'creationToResolutionDays',
        'resolutionDelayDays',
        'lastEscalation',
        'lastEscalationDate',
        'lastEscalationOrder',
        'escalations',
        'escalationsArchiveItems',
      ],
      ModuleKeywords.Issue
    );
  }
  updateEscalationPaths(body: { issues?: string[] }, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<Issue>>(
      this.url + this.updateEscalationPathsUrl,
      body,
      options
    ) as Observable<BaseResponse<Issue>>;
  }
}
