/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseAuditProjectEvent { 
    status?: number;
    data?: ApiResponseAuditProjectEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseAuditProjectEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_FIELD_WORK' | 'TO_CLOSED_COMPLETED' | 'TO_CLOSED_INCOMPLETE';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        FieldWork: 'TO_FIELD_WORK' as DataEnum,
        ClosedCompleted: 'TO_CLOSED_COMPLETED' as DataEnum,
        ClosedIncomplete: 'TO_CLOSED_INCOMPLETE' as DataEnum
    };
}


