import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppPermissions, DataTypeEnum, IFieldDefinition, ReportConfigurationTemplateDto, Standard, TargetTypeEnum, getEnumOptions } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class StandardMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'RES-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with RES-',
              },
            },
          ],
        },
      },
      calculationFunction: (params) => {
        return { name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' };
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Standard Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: Standard.StatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(ReportConfigurationTemplateDto.ReportFormatEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Reviewers',
      key: 'reviewers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Reviewers of the Standard',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          multi: true
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
      },
      calculationFunction: (params) => {
        return [{ name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' }];
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Approvers',
      key: 'approvers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approvers of the Standard',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          multi: true
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
      },
      calculationFunction: (params) => {
        return [{ name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' }];
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'ReviewedBy',
      key: 'reviewedBy',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      description: 'Users who should reviewedBy',
      tableDefinition: {
        filter: {
          extraKey: 'user',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'ReviewedBy',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionValue: 'user',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      }
    },
    {
      name: 'ApprovedBy',
      key: 'reviewedBy',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      description: 'Users who should appreovedBy',
      tableDefinition: {
        filter: {
          extraKey: 'user',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'ApprovedBy',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionValue: 'user',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      }
    },
    {
      name: 'Control Objectives',
      key: 'controlObjectives',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: false,
      permissions: AppPermissions.ReadControlObjective,
      description: 'Control Objectives linked to the standard',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ControlObjective,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['POLICY_BODY_SECTION'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
