import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuditCaseResultDto, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuditCaseResultMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditCaseResultDto.StatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Cases',
      key: 'cases',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Cases linked to the record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_CASE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: true,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: 'Cases',
          name: 'cases',
          placeholder: 'Search Cases',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['AUDIT_CASE'],
            },
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'code',
              multi: true,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              items: null
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Program',
      key: 'auditProgram',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Program of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_PROGRAM'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: 'Audit Program',
          name: 'auditProgram',
          placeholder: 'Search Audit Programs',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['AUDIT_PROGRAM'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'label',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Project',
      key: 'auditProject',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Project of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_PROGRAM'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Audit Projects',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['AUDIT_PROJECT'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'label',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditor',
      key: 'auditor',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditor of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditee',
      key: 'auditee',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditee of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Owner',
          name: 'owner',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditee Questionnaire',
      key: 'auditeeQuestionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Questionnaire of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['QUESTIONNAIRE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Questionnaire',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['QUESTIONNAIRE'],
            },
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'code',
              multi: false,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              items: null
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditee Answer',
      key: 'auditeeQuestionnaireValue',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Questionnaire of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['QUESTIONNAIRE_VALUE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Auditor Questionnaire',
      key: 'auditorQuestionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Questionnaire of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['QUESTIONNAIRE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Questionnaire',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['QUESTIONNAIRE'],
            },
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'code',
              multi: false,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              items: null
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditor Answer',
      key: 'auditorQuestionnaireValue',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Questionnaire of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['QUESTIONNAIRE_VALUE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Issues',
      key: 'issues',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Issue of this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['ISSUE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: true,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: 'Issues',
          name: 'issues',
          placeholder: 'Search Issues',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['ISSUE'],
            },
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'code',
              multi: true,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              items: null
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Audit Requests',
      key: 'auditRequests',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Requests of this Record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.AuditCaseResult,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: 'Audit Requests',
          name: 'auditRequests',
          placeholder: 'Search Requests',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['AUDIT_REQUEST'],
            },
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'code',
              multi: true,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              items: null
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
