/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccessRecord } from './accessRecord';
import { UserOperationPrinciple } from './userOperationPrinciple';


export interface TPVendorDto { 
    id?: string;
    searchScore?: number;
    uuid?: string;
    description?: string;
    recordStatus?: TPVendorDto.RecordStatusEnum;
    code?: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    version?: number;
    lockedForEdit?: boolean;
    creatorName?: string;
    lastModifierName?: string;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
    module?: TPVendorDto.ModuleEnum;
    systemGenerated?: boolean;
    parent?: string;
    parents?: Array<string>;
    translations?: { [key: string]: any; };
    systemLocked?: boolean;
    originalLanguage?: string;
    creationDate?: string;
    softDeleteDate?: string;
    lastModificationDate?: string;
    lockedUntil?: string;
    name?: string;
    contacts?: Array<string>;
    services?: Array<string>;
    owner?: UserOperationPrinciple;
    organization?: string;
    reviewers?: Array<UserOperationPrinciple>;
    approvers?: Array<UserOperationPrinciple>;
    dateOfFirstCollaboration?: string;
    website?: string;
    geographicalPresence?: string;
    assessmentEmailAddresses?: Array<string>;
    status?: TPVendorDto.StatusEnum;
}
export namespace TPVendorDto {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP' | 'TPRM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum,
        TprmApp: 'TPRM_APP' as ModuleEnum
    };
    export type StatusEnum = 'DRAFT' | 'REVIEW' | 'WAITING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'ASSESSMENT' | 'MONITOR' | 'TERMINATED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Review: 'REVIEW' as StatusEnum,
        WaitingApproval: 'WAITING_APPROVAL' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        Assessment: 'ASSESSMENT' as StatusEnum,
        Monitor: 'MONITOR' as StatusEnum,
        Terminated: 'TERMINATED' as StatusEnum
    };
}


