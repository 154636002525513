/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseTPRiskAssessmentEvent { 
    status?: number;
    data?: ApiResponseTPRiskAssessmentEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseTPRiskAssessmentEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_SUBMITTED_TO_TP' | 'TO_REVIEW' | 'TO_RISK_ANALYSIS' | 'TO_COMPLETED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        SubmittedToTp: 'TO_SUBMITTED_TO_TP' as DataEnum,
        Review: 'TO_REVIEW' as DataEnum,
        RiskAnalysis: 'TO_RISK_ANALYSIS' as DataEnum,
        Completed: 'TO_COMPLETED' as DataEnum
    };
}


