import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, RiskMethodology } from '@shared/classes';
import { find, maxBy } from 'lodash-es';

@Component({
  selector: 'app-risk-meter-view',
  templateUrl: './risk-meter-view.component.html',
  styleUrls: ['./risk-meter-view.component.scss'],
})
export class RiskMeterViewComponent extends BaseViewItem implements OnInit {
  progressBarList = [];
  maxRisk = 1;
  valueColor = '#8f8f8f';
  private _value;
  private _riskMeth;
  get value() {
    // console.log(this._value);
    return this._value;
  }
  get riskMeth() {
    // console.log(this._riskMeth);
    return this._riskMeth;
  }
  @Input() set value(value) {
    this._value = value;
    this.valueColor = this.getCellColor(this.riskMeth, value);
    this.data = value;
  }
  @Input() set riskMeth(riskMeth: RiskMethodology) {
    if (riskMeth) {
      this._riskMeth = riskMeth;
      this.valueColor = this.getCellColor(riskMeth, this.value);
      this.progressBarList = [];
      let highestImpact = maxBy(riskMeth.impactValuesDto, 'value');
      let highestLikelihood = maxBy(riskMeth.likelihoodValuesDto, 'value');
      this.maxRisk = highestImpact.value * highestLikelihood.value || 1;
      let previousStep = 0;
      riskMeth.thresholdValuesDto.forEach((value) => {
        this.progressBarList.push({
          value: ((value.max - previousStep) / this.maxRisk) * 100,
          tooltipHint: `${value.name} : ≤ ${value.max}`,
          styleBasedColor: value.color || 'transparent',
        });
        previousStep = value.max;
      });
      // riskMeth?.impactValues.forEach(impactValue => {
      //     riskMeth?.likelihoodValues.forEach(likelihoodValue => {
      //         let riskVal = (likelihoodValue.value * impactValue.value) || 0;
      //         this.progressBarList.push({
      //             value: (riskVal / this.maxRisk) * 100,
      //             tooltipHint: `${riskVal} : ${impactValue.name}(${impactValue.value}) x ${likelihoodValue.name}(${likelihoodValue.value})`,
      //             styleBasedColor: this.getCellColor(riskMeth, riskVal)
      //         });
      //     });
      // });
    }
  }
  constructor() {
    super();
  }

  ngOnInit(): void { }
  getCellColor(meth: RiskMethodology, cellValue) {
    let threshold = find(meth?.thresholdValuesDto, function (o) {
      return o.max >= cellValue && o.min <= cellValue;
    });

    if (threshold != undefined) return threshold.color;
    else return 'transparent';
  }
}
