import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { EventFieldDto, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class EventsDataService extends BaseRequestControllerService<EventFieldDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.entity,
      '/events',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'owner',
        'type',
        'entityTypeValue',
        'parent',
        'category',
        'dynamics',
        'treeViewMode',
        'organization',
        'responsibility',
        'ar-SY',
        // 'description',
      ],
      ModuleKeywords.Event
    );
  }
}
