/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseRemediationEvent { 
    status?: number;
    data?: ApiResponseRemediationEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseRemediationEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_SUBMITTED' | 'TO_STARTED' | 'TO_COMPLETED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Submitted: 'TO_SUBMITTED' as DataEnum,
        Started: 'TO_STARTED' as DataEnum,
        Completed: 'TO_COMPLETED' as DataEnum
    };
}


