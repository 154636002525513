import { find } from 'lodash-es';
import { RiskMethodology } from '../model';

export function getRiskCellColorByValue(meth: RiskMethodology, cellValue) {
  let threshold = find(meth?.thresholdValuesDto, function (o) {
    return o.max >= cellValue && o.min <= cellValue;
  });

  if (threshold != undefined) return threshold.color;
  else return 'transparent';
}
export function getRiskThresholdByValue(meth: RiskMethodology, cellValue) {
  let threshold = find(meth?.thresholdValuesDto, function (o) {
    return o.max >= cellValue && o.min <= cellValue;
  });

  if (threshold != undefined) return threshold;
  else return { value: -1, name: null, code: null, description: null, color: 'transparent' };
}
