import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ModuleKeywords } from '@shared/classes';
import { AuditObjective } from '@shared/classes/model/backend/audit/model/auditObjective';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuditObjectivesDataService extends BaseRequestControllerService<AuditObjective> {
  //auditProject
  //reporterNotes
  //charts
  //isFinal
  //issues
  //auditorFindings
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.audit,
      '/audit-objectives',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'description',
        'name',
        'label',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'parent',
      ],
      ModuleKeywords.AuditObjective
    );
  }
}
