import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DashboardDto, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class DashboardDataService extends BaseRequestControllerService<DashboardDto> {
  constructor(
    dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.dataStructure,
      '/dashboards',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'icon',
        'color',
        'defaultColorPalette',
      ],
      ModuleKeywords.Dashboard
    );
  }
}
