import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ModuleKeywords, RequestHandlerOptions, User } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends BaseRequestControllerService<User> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.user,
      '/user',
      [
        'id',
        'name',
        'userName',
        'email',
        // "enable",
        // "sfSysAdmin",
      ],
      ModuleKeywords.User
    );
  }
  searchUser(username: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<{ id: string; userName: string; email: string }[]>(
      this.url + `/search/${username}`,
      options
    ) as Observable<{ id: string; userName: string; email: string }[]>;
  }
  getSelector(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<{ id: string; userName: string; email: string }[]>(
      this.url + '/selector',
      options
    ) as Observable<{ id: string; userName: string; email: string }[]>;
  }
  searchUserAndGroup(
    name: string,
    body: {
      excludedItems: { users: string[]; groups: string[]; responsibility: string[] };
      types: ('USERS' | 'GROUPS' | 'RESPONSIBILITY')[];
    } = {
      excludedItems: { users: [], groups: [], responsibility: [] },
      types: ['USERS', 'GROUPS', 'RESPONSIBILITY'],
    },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<
      {
        id: string;
        userName: string;
        email: string;
        members?: string[];
      }[]
    >(this.url + `/groupAndUser/selector?name=${name}`, body, options) as Observable<
      {
        id: string;
        name: string;
        email: string;
        type: any;
        members?: string[];
      }[]
    >;
  }
  getUserAndGroupSelector(
    body: {
      excludedItems: { users: string[]; groups: string[]; responsibility: string[] };
      types: ('USERS' | 'GROUPS' | 'RESPONSIBILITY')[];
    } = {
      excludedItems: { users: [], groups: [], responsibility: [] },
      types: ['USERS', 'GROUPS', 'RESPONSIBILITY'],
    },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<
      {
        id: string;
        userName: string;
        email: string;
        members?: string[];
      }[]
    >(this.url + '/groupAndUser/selector', body, options) as Observable<
      {
        id: string;
        name: string;
        email: string;
        type: any;
        members?: string[];
      }[]
    >;
  }
  updateUser(body: User, userId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.putData<User>(this.url + '/update/' + userId, body, options) as Observable<User>;
  }
  updateProfile(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.putData<User>(this.url + '/profile', body, options) as Observable<User>;
  }
  createUser(body: User, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<User>(this.url + '/create', body, options) as Observable<User>;
  }
  changePassword(body: { password: string }, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<User>(this.url + '/change_password', body, options) as Observable<User>;
  }
  getByUsername(username: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<User[]>(this.url + '/exact/' + username, options) as Observable<User[]>;
  }
  getProfile(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<User[]>(this.url + '/profile', options) as Observable<User[]>;
  }
  getUserInfo(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    options = { ...new RequestHandlerOptions(), ...options };
    //old style : this.url+"/userInfo"
    return this.dataService.getData<any>(
      environment.keyCloak.config.url + '/realms/sightflare/protocol/openid-connect/userinfo',
      options
    ) as Observable<any>;
  }
  checkUsernameTaken(username: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    options = { ...new RequestHandlerOptions(), ...options };
    //old style : this.url+"/userInfo"
    return this.dataService.getData<any>(
      this.url + `/check-username-taken?username=${username}`,
      options
    ) as Observable<any>;
  }
  checkEmailTaken(emil: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    options = { ...new RequestHandlerOptions(), ...options };
    //old style : this.url+"/userInfo"
    return this.dataService.getData<any>(this.url + `/check-email-taken?email=${emil}`, options) as Observable<any>;
  }
}
