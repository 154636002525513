import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ModuleKeywords, ReportDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportDataService extends BaseRequestControllerService<ReportDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.dataStructure,
      '/reports',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'icon',
        'color',
        'defaultColorPalette',
      ],
      ModuleKeywords.Report
    );
  }
  createPdf<T = ReportDto>(code: string, body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<T>>(`${this.url}/pdf/${code}`, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  instantRunReport<T = ReportDto>(
    code: string,
    body: any,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<BaseResponse<T>>(`${this.url}/instant-run/${code}`, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  renderReport<T = ReportDto>(code: string, body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<T>>(`${this.url}/render/${code}`, body, options) as Observable<
      BaseResponse<T>
    >;
  }
}
