import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiResponsePolicyEvent,
  BaseResponse,
  ModuleKeywords,
  Policy,
  PolicyBody,
  RequestHandlerOptions,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolicyDataService extends BaseRequestControllerWithRuleService<Policy, ApiResponsePolicyEvent.DataEnum> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/policies',
      [
        'id',
        'code',
        'owner',
        'name',
        'tags',
        'validFrom',
        'validTo',
        'description',
        'policyBody',
        'externalReferences',
        'exceptionDuration',
        'exceptionDurationFrom',
        'exceptionDurationTo',
        'allowExceptions',
        'reviewers',
        'approvers',
        'approvedBy',
        'approvedActionDetails',
        'policyStatus',
        'reviewedBy',
        'progress',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
      ],
      ModuleKeywords.Policy
    );
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  createBody(body, policyId, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<any>>(this.url + `/${policyId}/body`, body, options) as Observable<
      BaseResponse<any>
    >;
  }
  saveVersion(body, policyId, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<any>>(this.url + `/save-version`, body, options) as Observable<
      BaseResponse<any>
    >;
  }

  public getPolicyBodyHistory(sourceCode: string) {
    const newLocal = this.url + `/history/${sourceCode}`;
    return this.dataService.getData<BaseResponse<any>>(newLocal, this.apiOptions) as Observable<BaseResponse<any>>;
  }

  navigateVersionPage(version, policyId) {
    this.router.navigateByUrl(`framework/policy/${policyId}/body/view/${version}`);
  }

  public getPolicyBody(policyIdOrCode, versionCode) {
    const newLocal = this.url + `/getPolicyBody/${policyIdOrCode}/${versionCode}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, this.apiOptions) as Observable<
      BaseResponse<any>
    >;
  }

  // changeStatus(status, policyId, options: RequestHandlerOptions = new RequestHandlerOptions()) {
  //     return this.dataService.postData<BaseResponse<any>>(this.url + `/changeStatus/${policyId}/${status}`, {}, options) as Observable<BaseResponse<any>>;
  // }

  public getNoFurtherComment(policyIdOrCode) {
    const newLocal = this.url + `/${policyIdOrCode}/no-further-comment`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, this.apiOptions) as Observable<
      BaseResponse<any>
    >;
  }

  public getApprove(policyIdOrCode) {
    const newLocal = this.url + `/${policyIdOrCode}/approve`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, this.apiOptions) as Observable<
      BaseResponse<any>
    >;
  }

  // getRuleHandlers(
  //     idOrCode: string,
  //     options: RequestHandlerOptions = new RequestHandlerOptions()
  // ) {
  //     return this.dataService.getData<FlatRule[]>(`${this.url}/get-policy-rule-handlers/${idOrCode}`, options) as Observable<FlatRule[]>;
  // }

  // public getPolicyRuleHandlers(sourceCode: string) {
  //     const newLocal = this.url + `/get-policy-rule-handlers/${sourceCode}`;
  //     return this.dataService.getData<FlatRule[]>(
  //         newLocal,
  //         this.apiOptions
  //     ) as Observable<FlatRule[]>;
  // }
}

// export namespace PolicyEvents {
//   export type PolicyEventsEnum =
//     | 'ADD_EXCEPTION'
//     | 'TO_DRAFT'
//     | 'TO_REVIEW'
//     | 'TO_APPROVED'
//     | 'TO_RETIRE'
//     | 'TO_WAITING_APPROVE'
//     | 'NO_FURTHER_COMMENT'
//     | 'APPROVE'
//     | 'REJECT'
//     | 'SEEMS_OK';
//   export const PolicyEventsEnum = {
//     TO_DRAFT: 'TO_DRAFT' as PolicyEventsEnum,
//     TO_REVIEW: 'TO_REVIEW' as PolicyEventsEnum,
//     TO_APPROVED: 'TO_APPROVED' as PolicyEventsEnum,
//     TO_RETIRE: 'TO_RETIRE' as PolicyEventsEnum,
//     TO_WAITING_APPROVE: 'TO_WAITING_APPROVE' as PolicyEventsEnum,
//     NO_FURTHER_COMMENT: 'NO_FURTHER_COMMENT' as PolicyEventsEnum,
//     APPROVE: 'APPROVE' as PolicyEventsEnum,
//     ADD_EXCEPTION: 'ADD_EXCEPTION' as PolicyEventsEnum,
//     SEEMS_OK: 'SEEMS_OK' as PolicyEventsEnum,
//     REJECT: 'REJECT' as PolicyEventsEnum,
//   };
// }
