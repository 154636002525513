import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  ApiResponseTaskEvent,
  BaseListResponse,
  BaseResponse,
  ModuleKeywords,
  RequestHandlerOptions,
  Task,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskDataService extends BaseRequestControllerWithRuleService<Task, ApiResponseTaskEvent.DataEnum> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.userOperations,
      '/tasks',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'name',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'version',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'relatedToType',
        'relatedToCode',
        'principle',
        'data',
        'status',
        'subject',
        'description',
        'priority',
        'completed',
        'sortId',
        'tags',
        'sender',
        'dueDate',
      ],
      ModuleKeywords.Task
    );
  }

  private _prepareFilters(data: any) {
    let filters = [];
    if (!!data?.searchTerm) {
      filters = [{ property: 'task', operation: 'CONTAINS', value: data.searchTerm }];
    }
    return filters;
  }

  getByIdOrCode<T>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(this.url + '/getByIdOrCode/' + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }

  myTasks(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<Task[]>(`${this.url}/my`, options) as Observable<BaseListResponse<Task>>;
  }
  toggleTask(taskId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<Task>(`${this.url}/toggle/${taskId}`, options) as Observable<BaseResponse<Task>>;
  }
  getContextValues(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData(`${this.url}/available-context-task-values`, options) as Observable<string[]>;
  }
}
