import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ComplianceReportResultMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: false,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Date',
      key: 'date',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: '2022-11-22',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dateInput: {
              showIcon: true,
              showTime: false
            },
          },
        },
      },
    },
    {
      name: 'Report',
      key: 'reportCode',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Report Linked to the results',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ComplianceReport,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      // permissions: AppPermissions.ReadGroup,
      description: 'Description of the report',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    // {
    //     name: "Items",
    //     key: "items",
    //     dataType: DataTypeEnum.Text,
    //     required: false,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: "Items Linked to the results",
    //     tableDefinition:{
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
  ];
  constructor() {
    super();
    this.suffixBaseFields = this.suffixBaseFields.filter(x => x.key != 'tags');
    this.setAllFields();
  }
}
