import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, GlobalPackageCount } from '@shared/classes';

@Component({
  selector: 'app-target-type-count-view',
  templateUrl: './target-type-count-view.component.html',
  styleUrl: './target-type-count-view.component.scss',
})
export class TargetTypeCountViewComponent extends BaseViewItem<GlobalPackageCount> implements OnInit {
  @Input() toolTip;
  @Input() customIcon;
  constructor() {
    super();
  }

  ngOnInit(): void { }
}
