import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: MCQComponent }],
})
export class MCQComponent extends DynamicComponentBase implements OnInit {
  @Input() groupItems: { label: string; value: any }[] = [];
  @Input() selectionMode: 'radio' | 'dropdown' = 'radio';
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() scored: boolean = false;
  @Input() showScore: boolean = false;
  @Input() passingScore: number = 0;
  tempFormControl = new FormControl(null);
  changesFlag = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    //this is a fix for radio button since the same object fed into the radio component should be in the fcontrol
    this.fControl.valueChanges.subscribe(x => {
      this.fControl?.patchValue(this.groupItems.find(item => item?.label === x?.label), { emitEvent: false });
    });
    this.fControl?.patchValue(this.fControl?.value);
  }

  setInputOptions() {
    this.groupItems = this.inputOptions?.radioInput?.groupItems ?? this.groupItems;
    this.selectionMode = this.inputOptions?.radioInput?.selectionMode ?? this.selectionMode;
    this.scored = this.inputOptions?.radioInput?.scored ?? this.scored;
    this.showScore = this.inputOptions?.radioInput?.showScore ?? this.showScore; //this needs to be checked
    this.passingScore = this.inputOptions?.radioInput?.passingScore ?? this.passingScore;
  }

  compareObjects(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.label === obj2.label : obj1 === obj2;
  }
}
