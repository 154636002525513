/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PolicyBodySectionTemplateProjection { 
    subject?: string;
    body?: string;
    type?: PolicyBodySectionTemplateProjection.TypeEnum;
    recordStatus?: PolicyBodySectionTemplateProjection.RecordStatusEnum;
}
export namespace PolicyBodySectionTemplateProjection {
    export type TypeEnum = 'GENERAL' | 'COMPLIANCE_REQUIREMENTS';
    export const TypeEnum = {
        General: 'GENERAL' as TypeEnum,
        ComplianceRequirements: 'COMPLIANCE_REQUIREMENTS' as TypeEnum
    };
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
}


