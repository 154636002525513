import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ModuleKeywords, RequestHandlerOptions } from '@shared/classes';
import { ApiResponseAuditProjectEvent, AuditProject } from '@shared/classes/model/backend/audit';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';

import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditProjectDataService extends BaseRequestControllerWithRuleService<
  AuditProject,
  ApiResponseAuditProjectEvent.DataEnum
> {
  myProjectManagerSearchUrl = '/my-project-manager';

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.audit,
      '/audit-projects',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'tags',
        'tagsCodes',
        'description',
        'name',
        'label',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'controlClassification',
        'auditProjectStatus',
        'auditUniverse',
        'auditProgram',
        'controls',
        'projectScope',
        'targetScope',
        'type',
        'manager',
        'entities',
        'approver',
        'reviewers',
        'owner',
        'plannedStartDate',
        'plannedEndDate',
        'plannedDuration',
        'actualStartDate',
        'actualEndDate',
        'actualDuration',
        'actualTimeResources',
        'manualControls',
        'state',
        'fieldworkCompleted',
        'budgetedExpenses',
        'budgetedResources',
      ],
      ModuleKeywords.AuditProject
    );
  }

  public getControls(codes, scope, universe, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/controls`;
    return this.dataService.postData<BaseResponse<string[]>>(
      newLocal,
      {
        codes: codes,
        scope: scope,
        universe: universe,
      },
      options
    ) as Observable<BaseResponse<any>>;
  }
}
