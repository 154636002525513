import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse, ControlObjective, ModuleKeywords, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlObjectiveDataService extends BaseRequestControllerWithRuleService<
  ControlObjective,
  ControlObjectiveEvent.ControlObjectiveEventEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/control-objectives',
      [
        'creatorName',
        'creationDate',
        'lastModifierName',
        'lastModificationDate',
        'id',
        'tags',
        'tagsCodes',
        'searchScore',
        'recordStatus',
        'translations',
        'originalLanguage',
        'uuid',
        'description',
        'code',
        'label',
        'parent',
        'parents',
        'module',
        'systemGenerated',
        'softDeleteDate',
        'name',
        'policySections',
        'citations',
        'codePrefix',
        'lockedUntil',
        'lockedForEdit',
        'lockedBy',
        'userAccessLevel',
        'controlCategory',
        'policySections',
        'citations',
      ],
      ModuleKeywords.ControlObjective
    );
  }

  toggleState<T>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(this.url + '/toggle/' + id, options) as Observable<
      BaseResponse<any>
    >;
  }
}

export namespace ControlObjectiveEvent {
  export type ControlObjectiveEventEnum = 'ADD_EXCEPTION' | 'TO_ACTIVE' | 'TO_INACTIVE';
  export const ControlObjectiveEventEnum = {
    TO_ACTIVE: 'TO_ACTIVE' as ControlObjectiveEventEnum,
    TO_INACTIVE: 'TO_INACTIVE' as ControlObjectiveEventEnum,
    ADD_EXCEPTION: 'ADD_EXCEPTION' as ControlObjectiveEventEnum,
  };
}
