<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && this.control.errors">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
      [class.p-float-label]="floatLabel"
      [class.p-input-icon-left]="leftIcon"
      [class.p-input-icon-right]="rightIcon"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
      @if (leftIcon) {
        <i [class]="' pi ' + leftIcon"></i>
      }
      @if (rightIcon) {
        <i [class]="' pi ' + rightIcon"></i>
      }
      <div
        class="ngx-ip-container"
        class="p-inputtext"
        [class.ng-invalid]="fControl?.invalid"
        [class.ng-dirty]="fControl?.dirty"
        [ngClass]="containerClass"
      >
        <div class="ngx-ip-table" [@inputAnim]="inputAnim">
          @for (idx of blocksRef; track idx; let isLast = $last) {
            <div class="ngx-ip-table-cell" [ngClass]="{ 'ngx-ip-error': highlightInvalidBlocks && invalidBlocks[idx] }">
              <input
                appMaxCharacterValidator
                #input
                type="text"
                [class.p-inputtext]="!isBlockDisabled(idx)"
                [id]
                [class.ngx-ip-disabled]="isBlockDisabled(idx)"
                [formControl]="formControls[idx]"
                [class.ng-invalid]="fControl?.invalid && formControls[idx]?.invalid"
                [class.ng-dirty]="fControl?.dirty && formControls[idx]?.dirty"
                [required]="required"
                [readonly]="readOnly"
                [value]="blocks[idx] || ''"
                (change)="onChange($event, idx)"
                (blur)="onBlur(idx)"
                (focus)="onFocus(idx)"
                [disabled]="isBlockDisabled(idx)"
                (paste)="onPaste($event, idx)"
                (copy)="onCopy($event, idx)"
                (keypress)="onKeyPress($event, idx)"
                (keydown)="onKeyDown($event, idx)"
                (keyup)="onKeyUp($event, idx)"
              />
            </div>
            <span class="ngx-ip-table-cell ngx-ip-sep">{{ separatorMap[idx] }}</span>
          }
        </div>

        @if (resolveCopyMethod) {
          <div class="ngx-ip-copy-overlay">
            <div class="ngx-ip-table" [@copyAnim]="">
              <div class="ngx-ip-copy-title">Copy?</div>
              <a (click)="onCopyDecision('block')">Block</a>
              <a (click)="onCopyDecision('address')">Address</a>
            </div>
          </div>
        }
      </div>
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('invalidNetworkValue')) {
        <small class="p-error p-invalid">{{ label }} {{ 'Invalid Value' }}</small>
      }
    }
    @if (helpText) {
      <small class="text-500 block">{{ helpText }}</small>
    }
  </div>
</app-input-view-switch>
