//@Todo remove this thing
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ControlObjective, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class StandardSectionDataService extends BaseRequestControllerService<ControlObjective> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/standard-sections',
      [
        'creatorName',
        'creationDate',
        'lastModifierName',
        'lastModificationDate',
        'id',
        'tags',
        'tagsCodes',
        'searchScore',
        'recordStatus',
        'translations',
        'originalLanguage',
        'uuid',
        'description',
        'code',
        'label',
        'parent',
        'parents',
        'module',
        'systemGenerated',
        'softDeleteDate',
        'name',
        'policySections',
        'citations',
        'codePrefix',
        'lockedUntil',
        'lockedForEdit',
        'lockedBy',
        'userAccessLevel',
      ],
      ModuleKeywords.Standard
    );
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/standard-section/list`);
  }
}
