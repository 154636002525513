import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import {
  DataTypeEnum,
  DynamicComponentBase,
  EntityTypeFieldDto,
  FilterItem,
  ModuleIcons,
  isNullObj,
  toPascalCase,
} from '@shared/classes';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { groupBy, uniqBy } from 'lodash-es';
import { TreeNode } from 'primeng/api';
import { Subject, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';

@Component({
  selector: 'app-user-and-group-tree-selector',
  templateUrl: './user-and-group-tree-selector.component.html',
  styleUrls: ['./user-and-group-tree-selector.component.scss'],
})
export class UserAndGroupTreeSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() showClear = true;
  @Input() multi = false;
  @Input() items: any[] = [];
  @Input() returnObject: boolean = false;
  @Input() useResponsibilityMode: boolean = false;
  @Input() excludeId: string = null;
  @Input() dataKey: string = 'name';
  @Input() customProjectionFields: string[] = [
    'id',
    'recordStatus',
    'code',
    'name',
    'owner',
    'type',
    'parent',
    'members',
    'category',
    'icon',
    'groupName',
    'responsibility',
  ];
  @Input() categoryList: ('RESPONSIBILITIES' | 'GROUPS' | 'USERS')[] = ['RESPONSIBILITIES'];
  @Input() appendTo: string = null;

  @Input() dropDown: boolean = true;
  optionsLoaded: boolean = false;
  entityTypeMap: { [x: string]: EntityTypeFieldDto } = {
    GROUP: { icon: 'pi pi-users' },
    RESPONSIBILITIES: { icon: 'pi pi-user-plus' },
    USER: { icon: 'pi pi-user' },
  };
  @Input() set selectedEntities(entities: string[] | any[]) {
    this.setSelectedItems(entities);
  }

  @Input() set extraFilters(filters: FilterItem[]) {
    this._extraFilters = filters;
    this.getOptions();
  }
  private _extraFilters: FilterItem[] = [];
  get extraFilters() {
    return this._extraFilters;
  }

  isLoading: boolean = false;

  @Input() tempMode: boolean = false;
  @Input() inlineMode: boolean = false;

  selectedNodes: any = null;
  groupedEntities: { [index: string]: any[] };
  groupedEntities1: { [index: string]: any[] };
  formattedEntities: TreeNode[];

  visited: any = {};
  treeNodes: any = {};
  responsibilityModeOptions = [
    { label: '', value: 'ONE', icon: 'pi pi-user', tooltip: 'One Person' },
    { label: '', value: 'ALL', icon: 'pi pi-users', tooltip: 'All People' },
  ];
  editModalVisible: boolean = false;
  editModalControl: FormControl = new FormControl(null);
  @Output() onChangesObject: EventEmitter<any> = new EventEmitter();
  @Input() principleModeSelection: 'ALL' | 'ONE' | 'SELECTION' = 'ONE';
  filterValueUpdate = new Subject<string>();
  dataType: DataTypeEnum = DataTypeEnum.PrincipleList;
  allUsersMap: { [x: string]: any } = {};
  @Input() useCustomBadgeMode: boolean = false;
  @Input() customBadgeColorField: string = 'color';
  @Input() customBadgeIconField: string = 'icon';
  @Input() showStatusBadge: boolean = true;

  inlineControl: FormControl = new FormControl(null);
  innerControl: FormControl = new FormControl(null);
  constructor(
    private service: UserDataService,
    private jwtService: JWTTokenService
  ) {
    super();
    // this.dataType = DataTypeEnum.CodeLink;
    this.filterValueUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.getOptions(value);
    });
  }

  ngOnInit() {
    this.syncSubscribers();

    if (this.viewMode != 'view') this.getOptions();

    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        if (this.tempMode) {
          if (this.multi) {
            this.selectedNodes = [...changes];
          } else {
            this.selectedNodes = changes;
          }
        }
        if (changes === null) {
          this.selectedEntities = [];
        }

        this.onChanges.emit(changes);
        // this.onChangesObject.emit(this.selectedNodes?.data);
        this.editModalControl.patchValue(
          this.returnObject != true
            ? this.control?.getRawValue()
            : this.control?.getRawValue()?.['label'] ||
            this.control?.getRawValue()?.['code'] ||
            this.control?.getRawValue()
        );
        this.inlineControl.patchValue(
          this.returnObject != true
            ? this.control?.getRawValue()
            : this.control?.getRawValue()?.['label'] ||
            this.control?.getRawValue()?.['code'] ||
            this.control?.getRawValue()
        );
      });
    }
  }
  // onSetViewMode(){
  //     if(this.viewMode == 'edit')
  //         this.getOptions();
  // }
  setSelectedItems(list: any[]) {
    let newSelections = null;
    Object.keys(this.treeNodes).forEach((nodeId) => {
      this.treeNodes[nodeId].data.mode = null;
      let nodeIdx = this.checkIfIncluded(nodeId, list);
      if (nodeIdx != -1) {
        if (this.treeNodes[nodeId]?.data && this.useResponsibilityMode)
          this.treeNodes[nodeId].data.mode = list[nodeIdx].mode;

        if (this.multi) {
          if (!newSelections) newSelections = [];
          newSelections.push(this.treeNodes[nodeId]);
        } else {
          newSelections = this.treeNodes[nodeId];
        }
      }
    });
    this.selectedNodes = newSelections;
  }
  unformattedOptions = [];
  getOptions(username?: any) {
    this.isLoading = true;
    // this.subs.sink = (isNullObj(username) ? this.service.getUserAndGroupSelector({showLoading:false,showMsg:false}) : this.service.searchUserAndGroup(username,{showLoading:false,showMsg:false})).subscribe({
    //     next:(res) =>{

    //         const resData = this.principleMode ? res.map(x => {return {name:x.name,principleType:x.type,mode:this.principleModeSelection != 'SELECTION' ?this.principleModeSelection : null}}): res;

    //         const allData = this.formatItemList(resData)?.filter(x=>  this.excludeSuggestions.indexOf(x.name) == -1);
    //         const responsibilities = allData.filter(x => (x[this.principleMode ? 'principleType' : 'type'] == 'GROUP') && (x.name as string)?.startsWith('RES-'));
    //         const groups = allData.filter(x => x[this.principleMode ? 'principleType' : 'type'] == 'GROUP' && !(x.name as string)?.startsWith('RES-'));
    //         const users = allData.filter(x => x[this.principleMode ? 'principleType' : 'type'] == 'USER');
    //         const tempItems = [];
    //         if(this.itemTypes?.indexOf('RESPONSIBILITIES') != -1 && responsibilities && responsibilities?.length > 0){
    //             tempItems.push({name:'Responsibilities',items:responsibilities,icon:'pi pi-user-plus'});
    //         }
    //         if(this.itemTypes?.indexOf('GROUPS') != -1 && groups && groups?.length > 0){
    //             tempItems.push({name:'Groups',items:groups,icon:'pi pi-users'});
    //         }
    //         if(this.itemTypes?.indexOf('USERS') != -1 && users && users?.length > 0){
    //             tempItems.push({name:'Users',items:users,icon:'pi pi-user'});
    //         }
    //         this.users = tempItems;
    //     },
    //     complete:()=>{
    //         this.isLoading = false;
    //     }
    // })
    this.subs.sink = forkJoin({
      data: isNullObj(username)
        ? this.service.getUserAndGroupSelector(
          {
            excludedItems: { users: [], groups: [], responsibility: [] },
            types: this.searchTypes,
          },
          { showLoading: false, showMsg: false }
        )
        : this.service.searchUserAndGroup(
          username,
          {
            excludedItems: { users: [], groups: [], responsibility: [] },
            types: this.searchTypes,
          },
          { showLoading: false, showMsg: false }
        ),
    }).subscribe({
      next: (data) => {
        this.allUsersMap = {};
        const currentUsername = this.jwtService.getPreferredUsername();
        data.data.forEach((element) => {
          const { code, label } = this.extractItemCode(element.name);
          this.allUsersMap[element.name] = {
            ...element,
            isMember: element?.members ? (element?.members?.find((x) => x == currentUsername) ? true : false) : false,
            responsibilityCode: element.type == 'RESPONSIBILITY' ? code : null,
            responsibilityName: element.type == 'RESPONSIBILITY' ? label : null,
          };
        });
        const resData = this.useResponsibilityMode
          ? data.data.map((x) => {
            return {
              name: x.name,
              principleType: x.type,
              mode: this.principleModeSelection != 'SELECTION' ? this.principleModeSelection : 'ONE',
              _uid: x?.name + '_' + x?.type,
            };
          })
          : data.data;

        this.unformattedOptions = resData;
        this.groupedEntities1 = {};
        let list = [];
        this.categoryList.forEach((element) => {
          if (element == 'RESPONSIBILITIES') {
            this.groupedEntities1[element] = data.data
              .filter((element) => element?.type == 'RESPONSIBILITY')
              .map((x) => {
                return { ...x, type: 'RESPONSIBILITY' };
              });
            list = list.concat(data.data.filter((element) => element.type == 'RESPONSIBILITY')).map((x) => {
              return { ...x, type: 'RESPONSIBILITY' };
            });
          } else if (element == 'GROUPS') {
            this.groupedEntities1[element] = data.data.filter((element) => element?.type == 'GROUP');
            list = list.concat(data.data.filter((element) => element?.type == 'GROUP'));
          } else if (element == 'USERS') {
            this.groupedEntities1[element] = data.data.filter((element) => element.type == 'USER');
            list = list.concat(data.data.filter((element) => element.type == 'USER'));
          }
        });
        this.formattedEntities = [];
        this.selectedNodes = null;
        let i = 0;
        Object.keys(this.groupedEntities1).forEach((category: 'RESPONSIBILITIES' | 'GROUPS' | 'USERS', index) => {
          if (this.categoryList.includes(category)) {
            this.formattedEntities.push({
              label: toPascalCase(category),
              expandedIcon: ModuleIcons[category],
              collapsedIcon: ModuleIcons[category],
              children: [],
              expanded: true,
              selectable: false,
            });

            this.groupedEntities = groupBy(this.groupedEntities1[category], 'parent');

            this.visited = {};
            this.formatDataForTreeView(list, i);
            this.items = uniqBy(list, 'code');
            i++;
          }
        });
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  extractItemCode(inputString) {
    // Regular expression to match the pattern "RES-[NUMBERS]"
    const regex = /(RES-\d+)(.*)/;

    // Find the first match in the input string
    const match = inputString.match(regex);

    // Extract the code and label if a match is found
    if (match) {
      return { code: match[1], label: match?.[2]?.substring(1) };
    } else {
      return { code: null, label: null };
    }
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.dropDown = this.inputOptions?.dropDownInput?.treeDropDown ?? this.dropDown;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.showClear = this.inputOptions?.dropDownInput?.showClear ?? this.showClear;
    this.categoryList = this.inputOptions?.extra?.categoryList ?? this.categoryList;
  }

  onValueChanges(value: any) {
    // this.data = value;

    if (this.multi) {
      if (this.returnObject) {
        let values: any[] = value?.map((item) => item?.data);
        this.fControl.patchValue(values);
      } else if (this.useResponsibilityMode) {
        let values: any[] = [];
        value?.forEach((item) => {
          if (item?.data?.[this.dataKey]) {
            item.data.mode =
              item?.data?.mode || (this.principleModeSelection != 'SELECTION' ? this.principleModeSelection : 'ONE');
            values.push({
              [this.dataKey]: item?.data?.[this.dataKey],
              mode: item?.data?.mode,
              principleType: item?.data?.type,
            });
          }
        });
        // let values: ResponsibilityItemFieldDto[] = value?.map(item => {return {code:item?.data?.[this.dataKey],mode:item?.data?.mode}});
        this.fControl.patchValue(values);
      } else {
        let values: string[] = value?.map((item) => item?.data[this.dataKey]);
        this.fControl.patchValue(values);
      }
    } else {
      if (this.returnObject) {
        this.fControl.patchValue(value?.data);
      } else if (this.useResponsibilityMode) {
        let val = null;
        if (value?.data?.[this.dataKey]) {
          value.data.mode =
            value?.data?.mode || (this.principleModeSelection != 'SELECTION' ? this.principleModeSelection : 'ONE');
          val = {
            [this.dataKey]: value?.data?.[this.dataKey],
            mode: value?.data?.mode,
            principleType: value?.data?.type,
          };
        }
        this.fControl.patchValue(val);
      } else {
        this.fControl.patchValue(value?.data[this.dataKey]);
      }
    }
  }
  onNodeUnselect(event) {
    event.node.data.mode = undefined;
  }
  formatDataForTreeView(data: any[], index) {
    this.formatDataForTreeViewRecursive(data, index);
  }
  formatDataForTreeViewRecursive(data: any[], index1) {
    this.formattedEntities[index1].data = data;
    this.formattedEntities[index1].type = 'root';

    this.groupedEntities['undefined']?.forEach((el, index) => {
      if (!!!this.visited[el[this.dataKey]]) {
        this.visited[el[this.dataKey]] = true;
        if (!(this.excludeId && el[this.dataKey] == this.excludeId))
          this.formattedEntities[index1].children.push(
            this.getFormattedChildren(el[this.dataKey], el, this.formattedEntities[index1].expandedIcon)
          );
      }
    });
  }
  getFormattedChildren(code, data: any, parentIcon): TreeNode {
    let node: TreeNode = {
      label: data.name,
      data: data,
      // expandedIcon: this.entityTypeMap[data?.type]?.icon ?? parentIcon,
      // collapsedIcon: this.entityTypeMap[data?.type]?.icon ?? parentIcon,
      // icon: this.entityTypeMap[data?.type]?.icon ?? parentIcon,
      children: [],
      expanded: true,
      selectable: this.viewMode != 'view',
      key: data[this.dataKey],
    };
    this.treeNodes[code] = node;
    if (this.checkIfIncludedInValue(code)) {
      if (this.useResponsibilityMode) {
        let val = this.getMatchingValue(code);
        node.data.mode = val.mode;
        this.treeNodes[code] = node;
      }
      if (this.multi) {
        if (!this.selectedNodes) this.selectedNodes = [];
        this.selectedNodes.push(node);
      } else {
        this.selectedNodes = node;
      }
    }
    if (!!this.groupedEntities[code]) {
      this.groupedEntities[code].forEach((el, index) => {
        if (!!!this.visited[el[this.dataKey]]) {
          this.visited[el[this.dataKey]] = true;
          let ret = this.getFormattedChildren(el[this.dataKey], el, node.icon);
          if (!(this.excludeId && el[this.dataKey] == this.excludeId)) node.children.push(ret);
        } else {
        }
      });
    } else {
      return node;
    }
    return node;
  }
  checkIfIncludedInValue(code) {
    const v = this.fControl?.getRawValue();

    if (!this.fControl?.getRawValue()) return false;
    if (!this.multi)
      return this.returnObject || this.useResponsibilityMode
        ? (this.fControl.getRawValue()[this.dataKey] as string) == code
        : (this.fControl.getRawValue() as string) == code;
    return (
      (this.fControl?.getRawValue() as string[])?.findIndex((x) =>
        this.returnObject || this.useResponsibilityMode ? x[this.dataKey] == code : x == code
      ) != -1
    );
  }
  getMatchingValue(code) {
    if (this.multi) {
      return (this.fControl?.getRawValue() as string[])?.find((x) =>
        this.returnObject || this.useResponsibilityMode ? x[this.dataKey] == code : x == code
      );
    } else {
      return this.fControl.getRawValue();
    }
  }
  // checkIfIncludedInSelectedNodes(code) {
  //     if (!this.selectedNodes) return false;
  //     return ((this.selectedNodes)?.findIndex(x => x.data[this.dataKey] == code) != -1)
  // }
  checkIfIncluded(code, data: any[]) {
    if (!data) return -1;
    return data?.findIndex((x) =>
      this.returnObject
        ? (x[this.dataKey] as string) == code
        : this.useResponsibilityMode
          ? (x?.[this.dataKey] as string) == code
          : (x as string) == code
    );
  }
  onChangeRespMode(mode: { originalEvent?: any; value?: any }, node) {
    const activeControl = this.editModalVisible ? this.inlineControl : this.fControl;

    mode?.originalEvent?.stopPropagation();
    const optionIndex = this.unformattedOptions.findIndex((x) => x?.[this.dataKey] == node?.[this.dataKey]);
    if (optionIndex != -1) {
      this.unformattedOptions[optionIndex].mode = mode.value;
    }
    if (this.multi) {
      let values = activeControl.getRawValue() ? [...activeControl.getRawValue()] : [];
      let ind = values?.findIndex((x) => x?.[this.dataKey] === node?.[this.dataKey]);
      if (ind != -1) {
        values[ind] = {
          [this.dataKey]: values[ind]?.[this.dataKey],
          mode: mode?.value,
          principleType: node?.principleType,
          _uid: node?._uid
        };
        activeControl.patchValue(values);
      } else {
        values = [
          ...values,
          { [this.dataKey]: node?.[this.dataKey], mode: mode?.value, principleType: node?.principleType, _uid: node?._uid },
        ];
        this.selectedNodes = [...(this.selectedNodes ? this.selectedNodes : []), this.treeNodes[node?.[this.dataKey]]];
        activeControl.patchValue(values);
      }
    } else {
      let val = { ...activeControl.getRawValue() };
      if (val?.[this.dataKey] === node?.data?.[this.dataKey]) {
        val = { ...val, mode: mode?.value, principleType: node?.principleType, _uid: node?._uid };
        activeControl.patchValue(val);
      }
    }
  }
  onOptionClick(event: { originalEvent?: any; option?: any; index?: any }, node) {
    event?.originalEvent?.stopPropagation();
    // if (node?.data?.selected == node?.data?.mode) {
    //     node.data.selected = null;
    //     node.data.mode = null;
    //     if (this.multi) {
    //         let values = this.fControl.getRawValue() ? [...this.fControl.getRawValue()] : [];
    //         let ind = values?.findIndex(x => x?.[this.dataKey] === node?.data?.[this.dataKey]);

    //         let ind2 = this.selectedNodes?.findIndex(x => x?.data?.[this.dataKey] === node?.data?.[this.dataKey]);

    //         if (ind2 != -1) {
    //             this.selectedNodes.splice(ind2, 1);
    //             this.selectedNodes = [...this.selectedNodes];
    //             setTimeout(() => {
    //                 this.formattedEntities.forEach(item => {
    //                     item.expanded = true;
    //                 })
    //                 this.formattedEntities = [...this.formattedEntities];
    //             }, 1);

    //         }
    //         if (ind != -1) {
    //             values.splice(ind, 1);
    //             // values[ind] = {[this.dataKey]:values[ind]?.[this.dataKey],mode:mode?.value};
    //             this.fControl.patchValue(values);
    //         } else {
    //             // values = [...values,{[this.dataKey]:node?.data?.[this.dataKey],mode:mode?.value}];
    //             // this.selectedNodes = [...(this.selectedNodes ? this.selectedNodes : []),this.treeNodes[node?.data?.[this.dataKey]]];
    //             // this.fControl.patchValue(values);
    //         }
    //     } else {
    //         // let val = {...this.fControl.getRawValue()};
    //         // if(val.[this.dataKey] === node?.data?.[this.dataKey]){
    //         //     val = {...val,mode:mode?.value};
    //         this.fControl.patchValue(null);
    //         // }
    //     }
    // } else {
    //     node.data.selected = node?.data?.mode?.toString();
    // }
  }
  openEditModal() {
    if (!!this.linkedControl?.invalid || this.linkedControl?.disabled || this.fControl.disabled) return;
    this.items = [];

    // this.currentTextFilterValue = [];
    let patchVal = this.multi
      ? this.control?.getRawValue()?.map((x) => {
        return { name: x.name, mode: x.mode, principleType: x.principleType };
      })
      : this.control?.getRawValue();
    this.editModalControl.patchValue(patchVal);
    this.inlineControl.patchValue(patchVal);
    this.editModalVisible = true;
  }
  onFocusChange(event) {
    // this.openEditModal();
  }
  onEditModalInputKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // Enter or Space key was pressed
      this.openEditModal();
    }
  }
  onEditModalSave() {
    let patchVal = this.multi
      ? this.inlineControl?.getRawValue()?.map((x) => {
        return { name: x.name, mode: x.mode, principleType: x.principleType };
      })
      : this.inlineControl?.getRawValue();
    this.fControl?.patchValue(patchVal);
    this.editModalVisible = false;
    // if(this.hasCurrentChangedObj)
    // this.onChangeObject(this.currentChangedObj);
  }
  public filterValue: string;
  onFilter(event) {
    // event?.originalEvent?.stopPropagation();
    // event?.originalEvent?.preventDefault();

    // this.getOptions(event);
    this.filterValueUpdate.next(event);
  }
  get getViewData() {
    let value = this.data || this.control.value;
    return value;
    // if (typeof value === 'string' || value instanceof String) {
    //     const code = extractItemCode(value as any)?.code;
    //     if(code) this.dataType = DataTypeEnum.CodeLink;
    //     else this.dataType = DataTypeEnum.Text;
    //     return code || value;
    // } else if (Array.isArray(value)) {
    //     const ret = value.map(x => x.name ? extractItemCode(x.name)?.code : (x?.principle ? extractItemCode(x.principle)?.code : x));
    //     this.dataType = DataTypeEnum.CodeLink;
    //     return ret;
    // } else {
    //     const ret = value?.name ? value?.name : (value?.principle ? value.principle : value);
    //     const code = extractItemCode(ret)?.code;
    //     if(code) this.dataType = DataTypeEnum.CodeLink;
    //     else this.dataType = DataTypeEnum.Text;
    //     return code || ret;
    // }

    // let value = (this.data || this.control.value);
    // if (typeof value === 'string' || value instanceof String) {
    //     return value;
    // } else if (Array.isArray(value)) {
    //     return value.map(x => x.name ? x.name : x);
    // } else {
    //     return value?.name ? value?.name : value;
    // }
  }
  get searchTypes() {
    return this.categoryList.map((type) => (type == 'RESPONSIBILITIES' ? 'RESPONSIBILITY' : type));
  }
  syncSubscribers() {
    // Sync fControl value to innerControl (add _uid)
    this.fControl.valueChanges.subscribe((value) => {
      if (value) {
        this.innerControl.setValue(
          value.map((x) => {
            return { ...x, _uid: x?.name + '_' + (x?.type || x?.principleType) };
          }),
          { emitEvent: false }
        );
      }
    });

    this.inlineControl.valueChanges.subscribe((value) => {
      if (value) {
        this.inlineControl.setValue(
          value.map((x) => {
            return { ...x, _uid: x?.name + '_' + (x?.type || x?.principleType) };
          }),
          { emitEvent: false }
        );
        this.innerControl.setValue(
          value.map((x) => {
            return { ...x, _uid: x?.name + '_' + (x?.type || x?.principleType) };
          }),
          { emitEvent: false }
        );
      }
    });

    // Sync innerControl back to fControl (remove _uid)
    this.innerControl.valueChanges.subscribe((value) => {
      if (value) {
        this.fControl.setValue(
          value.map(({ _uid, ...rest }) => rest),
          { emitEvent: false }
        );
      }
    });
  }
}
