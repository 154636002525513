import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTypeEnum, NumberInputModes, locales } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: NumberInputComponent }],
})
export class NumberInputComponent extends DynamicComponentBase implements OnInit {
  @Input() mode: keyof typeof NumberInputModes = 'decimal';
  @Input() showButtons = false;
  @Input() maxFractionDigits = 5;
  @Input() buttonLayout: 'stacked' | 'horizontal' | 'vertical' = 'stacked';
  @Input() incrementButtonClass = 'p-button-primary';
  @Input() decrementButtonClass = 'p-button-primary';
  @Input() incrementButtonIcon = 'pi pi-angle-up';
  @Input() decrementButtonIcon = 'pi pi-angle-down';
  @Input() min: number = -2147483640;
  @Input() max: number = 2147483640;
  @Input() currency: string = 'USD';
  @Input() locale: string = 'en-US';
  @ViewChild('currencyOP') currencyOP: OverlayPanel;
  dataType: DataTypeEnum = DataTypeEnum.Number;
  currencyDataType: DataTypeEnum = DataTypeEnum.CurrencyView;

  numberValue = null;
  currencyValue = null;
  uiChange = false;
  currencyList: any[];
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.fControl?.getRawValue() !== null) {
      if (this.mode == 'currency') {
        if (this.fControl?.getRawValue()?.currency) {
          this.currencyValue = this.fControl?.getRawValue().currency;
        }
        if (this.fControl?.getRawValue()?.value) {
          this.numberValue = this.fControl?.getRawValue().value;
        }
      } else {
        this.numberValue = this.fControl?.getRawValue();
      }
    }
    this.subs.sink = this.fControl.valueChanges.subscribe((values) => {
      if (this.uiChange) {
        this.uiChange = false;
      } else {
        if (this.mode == 'currency') {
          if (values?.currency) {
            const currencyObj = locales.find((x) => x.code == values.currency);
            if (currencyObj) {
              this.currency = currencyObj.code;
              this.locale = currencyObj.locale;
            }
            this.currencyValue = values.currency;
          }
          if (values?.value) {
            this.numberValue = values.value;
          }
        } else {
          this.numberValue = values;
        }
      }
    });
    this.currencyList = locales.map((item) => {
      return {
        label: item.code,
        code: item.code,
        locale: item.locale,
        codeLowerCase: item.code.toLowerCase(),

        // icon: 'pi pi-refresh',
        // command: () => {
        //     this.onSelectOption(item);
        // }
      };
    });
  }
  onSelectOption(item: { code: string; locale: string }) {
    this.currency = item.code;
    this.locale = item.locale;
    this.onChangeCurrency(item.code, null);
  }
  onChangeCurrency(item, overlayPanel) {
    if (this.mode == 'currency') {
      this.uiChange = true;
      this.currency = this.currencyValue.code;
      this.locale = this.currencyValue.locale;
      this.fControl.patchValue({ currency: this.currency, value: this.numberValue });
      overlayPanel?.hide();
    }
  }
  onChangeNumberValue(event) {
    this.uiChange = true;
    if (this.mode == 'currency') {
      if (this.numberValue === null) {
        this.fControl.patchValue(null);
      } else {
        this.fControl.patchValue({ currency: this.currency, value: this.numberValue });
      }
    } else {
      this.fControl.patchValue(this.numberValue);
    }
    this.fControl.markAllAsTouched();
    this.fControl.markAsDirty();
  }
  setInputOptions() {
    this.mode = this.inputOptions?.numberInput?.mode ?? this.mode;
    this.showButtons = this.inputOptions?.numberInput?.showButtons ?? this.showButtons;
    this.maxFractionDigits = this.inputOptions?.numberInput?.maxFractionDigits ?? this.maxFractionDigits;
    this.buttonLayout = this.inputOptions?.numberInput?.buttonLayout ?? this.buttonLayout;
    this.incrementButtonClass = this.inputOptions?.numberInput?.incrementButtonClass ?? this.incrementButtonClass;
    this.decrementButtonClass = this.inputOptions?.numberInput?.decrementButtonClass ?? this.decrementButtonClass;
    this.incrementButtonIcon = this.inputOptions?.numberInput?.incrementButtonIcon ?? this.incrementButtonIcon;
    this.decrementButtonIcon = this.inputOptions?.numberInput?.decrementButtonIcon ?? this.decrementButtonIcon;
    this.min = this.inputOptions?.numberInput?.min ?? this.min;
    this.max = this.inputOptions?.numberInput?.max ?? this.max;
  }
}
