/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AcceptedByItemDto } from './acceptedByItemDto';
import { AccessRecord } from './accessRecord';
import { RiskMethodologyImpactFactorOptionDto } from './riskMethodologyImpactFactorOptionDto';
import { RiskItemImpactFactorsDto } from './riskItemImpactFactorsDto';
import { UserOperationPrinciple } from './userOperationPrinciple';
import { RiskMethodologyImpactValueDto } from './riskMethodologyImpactValueDto';
import { RiskMethodologyLikelihoodValueDto } from './riskMethodologyLikelihoodValueDto';


export interface RiskItemDto { 
    id?: string;
    searchScore?: number;
    uuid?: string;
    description?: string;
    recordStatus?: RiskItemDto.RecordStatusEnum;
    code?: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    version?: number;
    lockedForEdit?: boolean;
    creatorName?: string;
    lastModifierName?: string;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
    module?: RiskItemDto.ModuleEnum;
    systemGenerated?: boolean;
    parent?: string;
    parents?: Array<string>;
    translations?: { [key: string]: any; };
    systemLocked?: boolean;
    originalLanguage?: string;
    creationDate?: string;
    softDeleteDate?: string;
    lastModificationDate?: string;
    lockedUntil?: string;
    name?: string;
    questionnaireValueCode?: string;
    owner?: UserOperationPrinciple;
    riskNotes?: string;
    comments?: string;
    status?: RiskItemDto.StatusEnum;
    strategy?: RiskItemDto.StrategyEnum;
    approver?: UserOperationPrinciple;
    riskStatement?: string;
    riskAssessmentId?: string;
    inheritedRiskImpact?: string;
    residualImpact?: string;
    controls?: Array<string>;
    inheritedLikelihood?: string;
    residualLikelihood?: string;
    inheritedRisk?: number;
    residualRisk?: number;
    riskThreats?: Array<string>;
    riskVulnerabilities?: Array<string>;
    category?: string;
    project?: string;
    entityId?: string;
    riskAssessment?: string;
    inheritedClassification?: string;
    residualClassification?: string;
    type?: RiskItemDto.TypeEnum;
    inheritedRiskAcceptance?: RiskItemDto.InheritedRiskAcceptanceEnum;
    residualRiskAcceptance?: RiskItemDto.ResidualRiskAcceptanceEnum;
    methodology?: string;
    oldRiskItem?: string;
    activeRiskAssessmentRequest?: string;
    inheritedRiskImpactDto?: RiskMethodologyImpactValueDto;
    inheritedLikelihoodDto?: RiskMethodologyLikelihoodValueDto;
    residualLikelihoodDto?: RiskMethodologyLikelihoodValueDto;
    impactFactors?: Array<RiskItemImpactFactorsDto>;
    residualImpactDto?: RiskMethodologyImpactValueDto;
    impactFactorOptions?: Array<string>;
    riskMethodologyImpactFactorOption?: Array<RiskMethodologyImpactFactorOptionDto>;
    acceptors?: Array<UserOperationPrinciple>;
    acceptedBy?: Array<AcceptedByItemDto>;
}
export namespace RiskItemDto {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum
    };
    export type StatusEnum = 'DRAFT' | 'SUBMITTED' | 'REJECTED' | 'RETIRED' | 'EXCEPTIONAL_ACCEPTANCE' | 'APPROVED' | 'REASSESS' | 'RESPONSE';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Submitted: 'SUBMITTED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        Retired: 'RETIRED' as StatusEnum,
        ExceptionalAcceptance: 'EXCEPTIONAL_ACCEPTANCE' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Reassess: 'REASSESS' as StatusEnum,
        Response: 'RESPONSE' as StatusEnum
    };
    export type StrategyEnum = 'EXCEPTIONAL_ACCEPTANCE' | 'MITIGATE' | 'TRANSFER' | 'ACCEPT' | 'AVOID';
    export const StrategyEnum = {
        ExceptionalAcceptance: 'EXCEPTIONAL_ACCEPTANCE' as StrategyEnum,
        Mitigate: 'MITIGATE' as StrategyEnum,
        Transfer: 'TRANSFER' as StrategyEnum,
        Accept: 'ACCEPT' as StrategyEnum,
        Avoid: 'AVOID' as StrategyEnum
    };
    export type TypeEnum = 'RISK_PROJECT' | 'MANUAL';
    export const TypeEnum = {
        RiskProject: 'RISK_PROJECT' as TypeEnum,
        Manual: 'MANUAL' as TypeEnum
    };
    export type InheritedRiskAcceptanceEnum = 'ACCEPTABLE' | 'NOT_ACCEPTABLE';
    export const InheritedRiskAcceptanceEnum = {
        Acceptable: 'ACCEPTABLE' as InheritedRiskAcceptanceEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as InheritedRiskAcceptanceEnum
    };
    export type ResidualRiskAcceptanceEnum = 'ACCEPTABLE' | 'NOT_ACCEPTABLE';
    export const ResidualRiskAcceptanceEnum = {
        Acceptable: 'ACCEPTABLE' as ResidualRiskAcceptanceEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as ResidualRiskAcceptanceEnum
    };
}


