<div
  [class]="
    'flex gap-2 select-none align-items-center justify-content-center' +
    (smallMode ? ' small-badge ' : ' badge ') +
    badgeTypes[badgeType] +
    (badgeColors[status] || badgeColors['NOT_DEFINED']) +
    ' ' +
    (flicker ? ' badge-shining ' : '') +
    (pulse ? ' badge-pulse ' : '') +
    styleClass
  "
  [style]="
    (customColor ? 'color:' + customColor + ' !important; ' : '') +
    (customColorBg && badgeTypes[badgeType] != outlineType
      ? 'background-color:' + customColorBg + ' !important; '
      : '') +
    ' min-height: 1.3rem; min-width: ' +
    minWidth +
    '; text-align: center; ' +
    (fullWidth ? ' max-width:100%; ' : ' ')
  "
  [pTooltip]="!(text || status) ? ('Empty Value' | translate) : ''"
  tooltipPosition="top"
>
  @if (
    leftSide &&
    (displayText || showIcon) &&
    (badgeIcons[status] || customIcon || (text || status ? badgeIcons['NOT_DEFINED'] : false))
  ) {
    <i
      [class]="customIcon || badgeIcons[status] || badgeIcons['NOT_DEFINED']"
      [style]="smallMode ? { 'font-size': '0.8rem' } : {}"
    ></i>
  }
  {{ displayText ? text || status || ' ' : null }}
  @if (
    !leftSide &&
    (displayText || showIcon) &&
    (badgeIcons[status] || customIcon || (text || status ? badgeIcons['NOT_DEFINED'] : false))
  ) {
    <i
      [class]="customIcon || badgeIcons[status] || badgeIcons['NOT_DEFINED']"
      [style]="smallMode ? { 'font-size': '0.8rem' } : {}"
    ></i>
  }
  <ng-content></ng-content>
</div>
