import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseAuditExpenseEvent, AuditExpense, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class AuditExpenseDataService extends BaseRequestControllerWithRuleService<
  AuditExpense,
  ApiResponseAuditExpenseEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.audit,
      '/audit-expenses',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'description',
        'name',
        'label',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'status',
        'auditor',
        'actualExpenses',
        'actualResources',
        'auditProgram',
        'auditProject',
        'caseResult',
        'state',
      ],
      ModuleKeywords.AuditExpense
    );
  }
}
