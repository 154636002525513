import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  ApiResponseRiskMethodologyEvent,
  BaseResponse,
  ModuleKeywords,
  RequestHandlerOptions,
  RiskMethodology,
  RiskMethodologyDto,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RiskMethodologyDataService extends BaseRequestControllerWithRuleService<
  RiskMethodologyDto,
  ApiResponseRiskMethodologyEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.risk,
      '/riskMethodology',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'status',
        'acceptableRisk',
        'owner',
        'approver',
        'publishedAt',
        'active',
      ],
      ModuleKeywords.RiskMethodology
    );
  }

  navigateToEditPage() {
    this.router.navigateByUrl(`risk/risk-methodology/edit`);
  }

  fetchActiveRiskMethodology(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<{ active: RiskMethodology }>(`${this.url}/active`, options) as Observable<{
      data: RiskMethodology;
    }>;
  }

  active(code, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<RiskMethodology>>(
      this.url + `/${code}` + '/active',
      null,
      options
    ) as Observable<BaseResponse<RiskMethodology>>;
  }

  addAndActivate(body, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<RiskMethodology>>(
      this.url + '/addAndActivate',
      body,
      options
    ) as Observable<BaseResponse<RiskMethodology>>;
  }
}
