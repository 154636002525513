/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseCampaignEvent { 
    status?: number;
    data?: ApiResponseCampaignEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseCampaignEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_STARTED' | 'TO_CLOSED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Started: 'TO_STARTED' as DataEnum,
        Closed: 'TO_CLOSED' as DataEnum
    };
}


