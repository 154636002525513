import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: ColorInputComponent }],
})
export class ColorInputComponent extends DynamicComponentBase implements OnInit {
  get color() {
    return this._color;
  }
  set color(val) {
    this._color = val;
    this.fControl.patchValue(val);
  }
  _color: string = '';
  flag = false;
  @Input() format: 'HEX' | 'RGB' | 'HSB' = 'RGB';
  @Input() attatchTo;

  dataType: DataTypeEnum = DataTypeEnum.Color;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.color = this.fControl?.getRawValue();
    this.subs.sink = this.fControl.valueChanges.subscribe((val) => {
      if (!this.flag) {
        this.flag = true;
        this.color = val;
      }
    });
  }

  setInputOptions() {
    this.format = this.inputOptions?.colorInput?.format ?? this.format;
    this.attatchTo = this.inputOptions?.colorInput?.attatchTo ?? this.attatchTo;
    this.disabled = this.inputOptions?.colorInput?.disabled ?? this.disabled;
  }
}
