import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationDto } from '@shared/classes/model/backend/task/model/notificationDto';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum, TargetTypeEnum } from '@shared/classes/view/view-enums';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { CheckBoxComponent } from '@shared/components/ui/input-fields/check-box/check-box.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Type',
      key: 'notificationType',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: NotificationDto.NotificationTypeEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Subscription',
      key: 'subscriptionType',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: NotificationDto.SubscriptionTypeEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Related to Code',
      key: 'relatedToCode',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.CodeLink,
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: Object.values(TargetTypeEnum),
          multi: true
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Related to Type',
      key: 'relatedToType',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TargetTypeEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Sender',
      key: 'sender',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.UserListView,
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: UserSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Auto',
      key: 'autoSubscription',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Seen By',
      key: 'seenBy',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.UserListView,
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: UserSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
  ];

  constructor() {
    super();
    this.setAllFields();
  }
}
