import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition, MetricDto, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class MetricMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Questionnaire',
      key: 'qsr',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Questionnaire linked to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
    },
    {
      name: 'Category',
      key: 'category',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Metric Category linked to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.MetricCategory,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the metric',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: MetricDto.StatusEnum,
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Type of the metric',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: MetricDto.TypeEnum,
        },
      },
    },
    {
      name: 'Frequency',
      key: 'frequency',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Frequency of the metric',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'Target',
      key: 'target',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Target of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Priority',
      key: 'priority',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Priority of the metric',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: MetricDto.PriorityEnum,
        },
      },
    },
    {
      name: 'Start Date',
      key: 'startDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Start Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the metric',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Reporter',
      key: 'reporter',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Reporter of the metric',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Calculation Type',
      key: 'calculationType',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Calculation Type of the metric',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: MetricDto.CalculationTypeEnum,
        },
      },
    },
    {
      name: 'Campaign Score',
      key: 'campaignScore',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Campaign Score of the metric',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: MetricDto.CampaignScoreEnum,
        },
      },
    },
    {
      name: 'Current Value',
      key: 'currentValue',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Current Value of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Min Value',
      key: 'minValue',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'min Value of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Max Value',
      key: 'maxValue',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Max Value of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Orange Threshold',
      key: 'orangeThreshold',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Orange Threshold of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Red Threshold',
      key: 'redThreshold',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Red Threshold of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Classification',
      key: 'classification',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Classification of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Aggregate From',
      key: 'aggregateFrom',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Aggregate From of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Aggregation Question',
      key: 'aggregationQuestion',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Aggregation Question of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
