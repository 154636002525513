/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseRiskItemEvent { 
    status?: number;
    data?: ApiResponseRiskItemEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseRiskItemEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_SUBMITTED' | 'TO_REJECTED' | 'TO_RETIRED' | 'TO_APPROVED' | 'REASSESS' | 'TO_RESPONSE' | 'START_REASSESSMENT' | 'TO_EXCEPTIONAL_ACCEPTANCE' | 'KEEP';
    export const DataEnum = {
        ToDraft: 'TO_DRAFT' as DataEnum,
        ToSubmitted: 'TO_SUBMITTED' as DataEnum,
        ToRejected: 'TO_REJECTED' as DataEnum,
        ToRetired: 'TO_RETIRED' as DataEnum,
        ToApproved: 'TO_APPROVED' as DataEnum,
        Reassess: 'REASSESS' as DataEnum,
        ToResponse: 'TO_RESPONSE' as DataEnum,
        StartReassessment: 'START_REASSESSMENT' as DataEnum,
        ToExceptionalAcceptance: 'TO_EXCEPTIONAL_ACCEPTANCE' as DataEnum,
        Keep: 'KEEP' as DataEnum
    };
}


