import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  ApiResponseRiskItemEvent,
  BaseResponse,
  ModuleKeywords,
  RequestHandlerOptions,
  RiskItem,
  Standard,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RiskItemDataService extends BaseRequestControllerWithRuleService<
  RiskItem,
  ApiResponseRiskItemEvent.DataEnum
> {
  constructor(
    public dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.risk,
      '/riskItem',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'entityId',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
        'residualRisk',
        'status',
        'strategy',
        'inheritedRisk',
        'residualLikelihood',
        'inheritedLikelihood',
        'residualImpact',
        'inheritedRiskImpact',
        'value',
        'inheritedClassification',
        'residualClassification',
        'residualLikelihoodDto.name',
        'approvedActions',
        'actionsLog',
        'owner',
        'approver',
        'methodology',
        'project',
      ],
      ModuleKeywords.RiskItem
    );
  }

  public toExceptionalAcceptReject(idOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${idOrCode}/accept/${mode}`;
    return this.dataService.getData<BaseResponse<Standard>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
}
