//@todo remove this thing
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseListResponse, BasePaginationResponse, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from 'app/shared/services/api/base-request-controller.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntityDataService extends BaseRequestControllerService<any> {
  private getEntitiesByLevelUrl: string = '/searchByParentLevel/';
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.entity,
      '/entity',
      [
        'id',
        'code',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'owner',
        'level',
        'type',
        'entityTypeValue',
        'parent',
        'people',
        'category',
        'dynamics',
        'treeViewMode',
        'organization',
      ],
      null
    );
  }
  navigateToListPage() {
    this.router.navigateByUrl(`entity/list`);
  }
  getEntitiesByLevel(level: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseListResponse<any>>(
      this.url + this.getEntitiesByLevelUrl + level,
      options
    ) as Observable<BaseListResponse<any>>;
  }
  searchEntitiesByLevel(
    level: string,
    entityName: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.getData<BasePaginationResponse<any>>(
      this.url + this.getEntitiesByLevelUrl + level + '/' + entityName,
      options
    ) as Observable<BasePaginationResponse<any>>;
  }
}
