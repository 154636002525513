import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ModuleKeywords, RiskStatementCategory } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class RiskStatementCategoryDataService extends BaseRequestControllerService<RiskStatementCategory> {
  constructor(
    dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.risk,
      '/riskStatementCategory',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
      ],
      ModuleKeywords.RiskStatementCategory
    );
  }
}
