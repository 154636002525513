/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AcceptedByItemDto { 
    user?: string;
    acceptDate?: string;
    acceptMode?: AcceptedByItemDto.AcceptModeEnum;
}
export namespace AcceptedByItemDto {
    export type AcceptModeEnum = 'ACCEPT' | 'REJECT';
    export const AcceptModeEnum = {
        Accept: 'ACCEPT' as AcceptModeEnum,
        Reject: 'REJECT' as AcceptModeEnum
    };
}


