/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RiskEntityDto { 
    code?: string;
    residualRisk?: number;
    residualImpact?: string;
    residualLikelihood?: string;
    strategy?: RiskEntityDto.StrategyEnum;
    residualClassification?: string;
}
export namespace RiskEntityDto {
    export type StrategyEnum = 'EXCEPTIONAL_ACCEPTANCE' | 'MITIGATE' | 'TRANSFER' | 'ACCEPT' | 'AVOID';
    export const StrategyEnum = {
        ExceptionalAcceptance: 'EXCEPTIONAL_ACCEPTANCE' as StrategyEnum,
        Mitigate: 'MITIGATE' as StrategyEnum,
        Transfer: 'TRANSFER' as StrategyEnum,
        Accept: 'ACCEPT' as StrategyEnum,
        Avoid: 'AVOID' as StrategyEnum
    };
}


