import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { IconSelectorComponent } from '@shared/components/selectors/icon-selector/icon-selector.component';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { ColorInputComponent } from '@shared/components/ui/input-fields/color-input/color-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ReportMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Icon',
      key: 'icon',
      dataType: DataTypeEnum.Icon,
      required: false,
      showInTable: true,
      description: 'Record Icon',
      tableDefinition: {
        sortDisabled: true,
      },
      useInBulk: true,
      formField: {
        componentType: IconSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Color',
      key: 'color',
      dataType: DataTypeEnum.Color,
      required: true,
      showInTable: true,
      description: 'Record Color',
      tableDefinition: {
        filter: {
          type: 'color',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: true,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: ColorInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Report Template',
      key: 'reportTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Report to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ReportConfigurationTemplate,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Records',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['REPORT_CONFIGURATION_TEMPLATE'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'label',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
