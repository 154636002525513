import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  ApiResponseListCitation,
  BaseResponse,
  Citation,
  ModuleKeywords,
  RequestHandlerOptions,
} from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CitationDataService extends BaseRequestControllerService<Citation> {
  public unLinkingString = 'citations';

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/citations',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'userId',
        'name',
        'title',
        'type',
        'description',
        'frameworkRef',
        'citationType',
        'authorityDocument',
        'parent',
        'isExempted',
        'lockedBy',
        'mandatory',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
      ],
      ModuleKeywords.Citation
    );
  }

  navigateToListPage(params: { authID: string }) {
    this.router.navigateByUrl(`framework/authority/citation/list/${params.authID}`);
  }
  fetchCitationTree(
    authDocUniqueId: string,
    parentUniqueId: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ): Observable<ApiResponseListCitation> {
    options.params = { authDocUniqueId, parentUniqueId };
    return this.dataService.getData(`${this.url}/getAllForTree`, options) as Observable<ApiResponseListCitation>;
  }

  linkControlObjectives(
    code: string,
    body: { link: string[]; unLink: string[]; addAndLink: string[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<BaseResponse<any>>(this.url + '/' + code + '/link', body, options) as Observable<
      BaseResponse<any>
    >;
  }
}
