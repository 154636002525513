<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [labelSuffix]="': ' + (control?.value || data || 0) + valueSuffix"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
      [class.p-float-label]="floatLabel"
    >
      @if (label) {
        <label [class]="'md:w-12rem md:max-w-12rem md:min-w-12rem input-field-label ' + labelClassStyle" [for]="name"
          >{{ label }} : {{ !range ? control?.value || 0 : '' }}{{ valueSuffix }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      }
      <!-- tabindex for buttons is not published yet -->
      <span class="w-full">
        <div class="flex gap-3 align-items-center">
          @if (min != undefined) {
            <small>{{ min }}</small>
          }
          <p-slider
            class="w-full"
            [formControl]="fControl"
            [animate]="animate"
            [orientation]="orientation"
            [step]="step"
            [range]="range"
            [min]="min"
            [max]="max"
          ></p-slider>
          @if (max != undefined) {
            <small>{{ max }}</small>
          }
        </div>
        <span class="flex align-items-center w-100 mt-2 gap-3" [class]="range ? '' : 'justify-content-center'">
          <!-- <p-inplace
            [closable]="true"
            styleClass="inplace-align-super"
            [style]="{ verticalAlign: 'super' }"
            [disabled]="viewMode == 'view'"
            [preventClick]="viewMode == 'view'"
          > -->
          <!-- <ng-template pTemplate="display">
              <small>{{ fControl?.value }}</small>
            </ng-template> -->
          <!-- <label [class]="'mb-3 input-field-label'">
              {{ 'general.actions.from' | translate }}
            </label>           -->
          <!-- @if (!range) {
            <div></div>
          } -->
          <p-inplace
            [closable]="true"
            [class]="!range ? '' : 'w-full'"
            styleClass="inplace-align-super"
            [style]="{ verticalAlign: 'super' }"
            [disabled]="disabled || inputValue1?.disabled || viewMode == 'view'"
            [preventClick]="disabled || inputValue1?.disabled || viewMode == 'view'"
          >
            <ng-template pTemplate="display">
              <app-target-type-count-view
                [customIcon]="' '"
                [toolTip]="range ? ('general.actions.from' | translate) : ('general.actions.value' | translate)"
                [data]="{
                  type: range ? ('general.actions.from' | translate) : ('general.actions.value' | translate),
                  count: inputValue1?.value,
                }"
              ></app-target-type-count-view>
            </ng-template>
            <ng-template pTemplate="content">
              <app-number-input
                [name]="'min'"
                [showButtons]="true"
                [control]="inputValue1"
                [type]="'number'"
                [viewMode]="viewMode"
                [maxFractionDigits]="0"
                [min]="min"
                [max]="range ? inputValue2?.value : max"
              >
              </app-number-input>
            </ng-template>
          </p-inplace>
          <!-- @if (!range) {
            <div></div>
          } -->
          <!-- <label [class]="'mb-3 input-field-label'">
              {{ 'general.actions.to' | translate }}
            </label> -->
          @if (range) {
            <p-inplace
              [closable]="true"
              [class]="'w-full'"
              styleClass="inplace-align-super"
              [style]="{ verticalAlign: 'super' }"
              [disabled]="disabled || viewMode == 'view'"
              [preventClick]="disabled || viewMode == 'view'"
            >
              <ng-template pTemplate="display">
                <app-target-type-count-view
                  [customIcon]="' '"
                  [toolTip]="'general.actions.to' | translate"
                  [data]="{ type: 'general.actions.to' | translate, count: inputValue1?.value }"
                ></app-target-type-count-view>
              </ng-template>
              <ng-template pTemplate="content">
                <app-number-input
                  [name]="'max'"
                  [showButtons]="true"
                  [control]="inputValue2"
                  [type]="'number'"
                  [viewMode]="viewMode"
                  [maxFractionDigits]="0"
                  [min]="inputValue1?.value"
                  [max]="max"
                >
                </app-number-input>
              </ng-template>
            </p-inplace>
          }
          <!-- </p-inplace> -->
        </span>
      </span>
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('pattern') || control?.hasError('min') || control?.hasError('max')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (customError) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
</app-input-view-switch>
