import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseRiskAssessmentRequestEvent, ModuleKeywords, RiskAssessmentRequestDto } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class RiskAssessmentRequestDataService extends BaseRequestControllerWithRuleService<
  RiskAssessmentRequestDto,
  ApiResponseRiskAssessmentRequestEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.risk,
      '/riskAssessmentRequest',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'name',
        'lockedBy',
        'lockedForEdit',
        'questionnaireAnswered',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'status',
        'riskProject',
        'entity',
        'owner',
        'name',
        'riskStatement',
        'questionnaireValueCode',
        'projectType',
        'methodology',
        'assessmentType',
      ],
      ModuleKeywords.RiskAssessmentRequest
    );
  }
}
