import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-p-slider-input',
  templateUrl: './p-slider-input.component.html',
  styleUrls: ['./p-slider-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: PSliderInputComponent }],
})
export class PSliderInputComponent extends DynamicComponentBase implements OnInit {
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() animate = true;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() step: number = 1;
  @Input() range = false;
  @Input() valueSuffix = '';
  @Input() labelClassStyle = '';
  @Input() disabledMin: boolean = false;
  dontUpdateFlag = false;
  dataType: DataTypeEnum = DataTypeEnum.ProgressBar;
  inputValue1 = new FormControl(0);
  inputValue2 = new FormControl(0);
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.disabledMin) {
      this.inputValue1.disable();
    }
    this.inputValue1.patchValue(this.min);
    this.inputValue2.patchValue(this.max);
    if (!this.disabledMin) {
      this.subs.sink = this.inputValue1.valueChanges.subscribe(value => {
        if (value <= this.max && value >= this.min) {
          this.dontUpdateFlag = true;
          if (this.range) {
            const current = this.control.value;
            this.control.patchValue([value, current?.[1]]);
          } else {
            this.control.patchValue(value);
          }
        }
      });
    }
    if (this.range) {
      this.subs.sink = this.inputValue2.valueChanges.subscribe(value => {
        if (value <= this.max && value >= this.min) {
          this.dontUpdateFlag = true;
          const current = this.control.value;
          this.control.patchValue([current?.[0], value]);
        }
      });
    }

    if (this.range) {
      this.inputValue1.patchValue(this.control?.value?.[0]);
      this.inputValue2.patchValue(this.control?.value?.[1]);
    } else {
      this.inputValue1.patchValue(this.control?.value);
    }
    this.control.valueChanges.subscribe(value => {
      if (!this.dontUpdateFlag) {
        if (this.range) {
          if (!this.disabledMin) {
            this.inputValue1.patchValue(value?.[0]);
          } else {
            this.control.patchValue([this.inputValue1?.value, value?.[1]], { emitEvent: false });
          }
          this.inputValue2.patchValue(value?.[1]);
        } else {
          this.inputValue1.patchValue(value);
        }
      } else {
        this.dontUpdateFlag = false;
      }
    })
  }

  setInputOptions() {
    this.animate = this.inputOptions?.sliderInput.animate;
    this.orientation = this.inputOptions?.sliderInput.orientation;
    this.range = this.inputOptions?.sliderInput.range;
  }
}
