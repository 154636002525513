import { Component, Input, OnInit } from '@angular/core';
import { isNullObj, lightenColor } from '@shared/classes';
import { BadgeColor, BadgeIcon, BadgeType } from 'app/shared/classes/view/view-enums';

@Component({
  selector: 'app-badge-item',
  templateUrl: './badge-item.component.html',
  styleUrls: ['./badge-item.component.scss'],
})
export class BadgeItemComponent implements OnInit {
  private defaultColor = '#85838d';
  badgeIcons = BadgeIcon;
  badgeColors = BadgeColor;
  badgeTypes = BadgeType;
  outlineType = BadgeType.outline;
  private _status: string = 'NOT_DEFINED';
  @Input() set status(status: string) {
    this._status = status;
    this.onSetColor();
  }
  get status() {
    return this._status;
  }
  @Input() badgeType: 'solid' | 'outline' | 'custom' = 'custom';
  @Input() smallMode: boolean = false;
  @Input() text: string = null;
  @Input() displayText: boolean = true;
  @Input() fullWidth: boolean = false;
  @Input() minWidth: string = '4rem';
  @Input() styleClass: string = '';
  @Input() showIcon: boolean = false;
  @Input() set customColor(customColor: string) {
    if (!isNullObj(customColor)) {
      this.solidColor = customColor;
      this._customColor = lightenColor(customColor, 0); //hexToRgba(customColor, 1);
      this.customColorBg = lightenColor(customColor, 60); // hexToRgba(customColor, 0.15);
    }
  }
  @Input() customIcon: string = null;
  @Input() flicker: boolean = false;
  @Input() pulse: boolean = false;
  @Input() leftSide: boolean = true;

  customColorBg: string = null;
  private _customColor: string = null;
  get customColor(): string {
    return this._customColor;
  }
  // @todo when you need to make color accept both names and hex value use this
  // private _color = '#85838d';
  // @Input() set color(color: string) {
  //   this._color = color;
  //   this.onSetColor();
  // }
  // get color() {
  //   return this._color;
  // }
  solidColor = this.defaultColor;
  // bgColor = '#cecdd1';

  constructor() { }

  ngOnInit(): void {
    // if(!(this.customIcon || this.badgeIcons[this.status]))
  }
  //@todo when you need to make color accept both names and hex value use this
  onSetColor() {
    //@todo use some other variable beside custom color, because it will cause override issues
    this.customColor = lightenColor(this.badgeColors[this.status], 40) || this.defaultColor;
    // this.solidColor = lightenColor(this.color, 30) || this.solidColor;
    // this.bgColor = lightenColor(this.color, 80) || this.bgColor;
  }
}
