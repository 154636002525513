import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { ColorInputComponent } from '@shared/components/ui/input-fields/color-input/color-input.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root'
})
export class RiskMethodologyThresholdValueMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Min',
      key: 'min',
      dataType: DataTypeEnum.Number,
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Max',
      key: 'max',
      dataType: DataTypeEnum.Number,
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Color',
      key: 'color',
      dataType: DataTypeEnum.Color,
      required: true,
      showInTable: true,
      description: 'Record Color',
      tableDefinition: {
        filter: {
          type: 'color',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: true,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: ColorInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
