import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition, RiskMethodologyLikelihoodValueDto, getEnumOptions } from '@shared/classes';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root'
})
export class RiskMethodologyLikelihoodValueMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Value',
      key: 'value',
      dataType: DataTypeEnum.Number,
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Occures From',
      key: 'occuresFrom',
      dataType: DataTypeEnum.Number,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Occures To',
      key: 'occuresTo',
      dataType: DataTypeEnum.Number,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Occurrence Duration',
      key: 'occurrenceDuration',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Type of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RiskMethodologyLikelihoodValueDto.OccurrenceDurationEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(RiskMethodologyLikelihoodValueDto.OccurrenceDurationEnum),
              multi: false
            },
          },
        },
      },

    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
