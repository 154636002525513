import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuditProgram } from '@shared/classes/model/backend/audit';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum } from '@shared/classes/view/view-enums';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { TimeInputComponent } from '@shared/components/ui/input-fields/time-input/time-input.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class AuditProgramMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Audit Program Status',
      key: 'auditProgramStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Audit Program Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditProgram.AuditProgramStatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approver of the record',
      tableDefinition: {
        filter: {
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    // {
    //   name: "Reviewers",
    //   key: "reviewers",
    //   dataType: DataTypeEnum.ResponsibilityListView,
    //   required: true,
    //   showInTable: true,
    //   // permissions: AppPermissions.ReadGroup,
    //   description: "Reviewers of the record",
    //   tableDefinition: {
    //     filter: {
    //       type: "text",
    //       display: "menu",
    //       matchMode: "startsWith",
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    // },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Manager',
      key: 'manager',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Audit Program Type',
      key: 'type',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Program Type to this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_PROGRAM_TYPE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['AUDIT_PROGRAM_TYPE'],
            },
            dropDownInput: {
              optionLabel: 'name',
              optionValue: 'code',
              multi: false,
              items: undefined,
            },
          },
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Audit Universe',
      key: 'auditUniverse',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Universe to this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_UNIVERSE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['AUDIT_UNIVERSE'],
            },
            dropDownInput: {
              optionLabel: 'name',
              optionValue: 'code',
              multi: false,
              items: undefined,
            },
          },
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Budgeted Expenses',
      key: 'budgetedExpenses',
      dataType: DataTypeEnum.CurrencyView,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Budgeted Expenses to this Record',
      tableDefinition: {
        filter: {
          extraKey: 'value',
          type: 'numeric',
          matchMode: 'equals',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          dontUseKeyForFilter: true
        },
      },
      formField: {
        componentType: NumberInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Planned Expenses',
      key: 'plannedExpenses',
      dataType: DataTypeEnum.CurrencyView,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Planned Expenses to this Record',
      tableDefinition: {
        filter: {
          extraKey: 'value',
          type: 'numeric',
          matchMode: 'equals',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          dontUseKeyForFilter: true
        },
      },
      formField: {
        componentType: NumberInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Budgeted Resources',
      key: 'budgetedResources',
      dataType: DataTypeEnum.TimeView,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Budgeted Resources to this Record',
      tableDefinition: {
        filter: {
          extraKey: 'effectiveTime',
          type: 'timeline',
          matchMode: 'equals',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TimeInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Planned Resources',
      key: 'plannedResources',
      dataType: DataTypeEnum.TimeView,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Planned Resources to this Record',
      tableDefinition: {
        filter: {
          extraKey: 'effectiveTime',
          type: 'timeline',
          matchMode: 'equals',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TimeInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Start Date',
      key: 'startDate',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Start date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Start Date',
      key: 'endDate',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Start date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
