import { BreadCrumb } from '../model';
import { CodeMapper, IViewMode, ModuleKeywordRootPath, ModuleKeywords } from '../view';
import { humanizeCasedString, isNullObj, toKebabCase } from './general-functions';
export function getModuleKeywordBaseRoute(moduleKeyword: ModuleKeywords) {
  if (isNullObj(moduleKeyword)) return '';

  let rootPath = ModuleKeywordRootPath[moduleKeyword];
  let path = `/${rootPath ? rootPath + '/' : ''}${toKebabCase(moduleKeyword)}`;
  return path;
}
export function getListRouteByModuleKeyword(moduleKeyword: ModuleKeywords, listSubUrl = 'list') {
  return `${getModuleKeywordBaseRoute(moduleKeyword)}/${listSubUrl}`;
}
export function getPathByCode(code: string, viewMode: IViewMode = 'view', data: any = null) {
  let moduleKeyword = getModuleKeywordByCode(code);
  if (isNullObj(moduleKeyword)) return '';

  let rootPath = ModuleKeywordRootPath[moduleKeyword];
  let path = `/${rootPath ? rootPath + '/' : ''}${toKebabCase(moduleKeyword)}/${viewMode}/${code}`;

  return path;
}
export function getModuleKeywordPath(moduleKeyword: string) {
  if (isNullObj(moduleKeyword)) return '';

  let rootPath = ModuleKeywordRootPath[moduleKeyword];
  let path = `/${rootPath ? rootPath + '/' : ''}${toKebabCase(moduleKeyword)}`;
  return path;
}
export function getModuleKeywordByCode(code: string): string {
  if (isNullObj(code) || typeof code !== 'string') return '';

  let moduleKeyword = CodeMapper[code?.substring(0, 3)];
  //IF MAPPER IS NOT CODED
  if (moduleKeyword == null || moduleKeyword == undefined) {
    return '';
    // throw new Error("Mapper Not Defined for code : " + code);
  }
  return moduleKeyword;
}

export function getBreadCrumbByCode(code: string, currentPageLabel: string): BreadCrumb {
  const moduleKeyword = getModuleKeywordByCode(code);
  const rootPath = ModuleKeywordRootPath[moduleKeyword];

  let breadcrumb = {
    items: [
      { label: humanizeCasedString(rootPath || moduleKeyword) },
      {
        label: `${humanizeCasedString(moduleKeyword)}`,
        routerLink: [`/${rootPath ? rootPath + '/' : ''}${toKebabCase(moduleKeyword)}/list`],
      },
      {
        label: code,
        routerLink: [getPathByCode(code)],
      },
      {
        label: currentPageLabel,
        routerLink: [],
      },
    ],
  };
  return breadcrumb;
}
export function isValidCode(code: string): boolean {
  if (isNullObj(code) || typeof code !== 'string') return false;

  let moduleKeyword = CodeMapper[code?.substring(0, 3)];
  if (isNullObj(moduleKeyword) || isNaN(Number.parseInt(code?.substring(3)))) {
    return false;
  }
  return true;
}
