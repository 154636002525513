import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseListResponse, ModuleKeywords, RequestHandlerOptions, ResponsibilityFieldDto } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsibilitiesDataService extends BaseRequestControllerWithRuleService<
  ResponsibilityFieldDto,
  ResponsibilityEvents
> {
  private readonly getMyResponsibilitiesUrl = `/getMyResponsibilities/`;
  private readonly prepareResponsibilityUrl = `/prepare-responsibility`;
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.entity,
      '/responsibilities',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'type',
        'entityTypeValue',
        'parent',
        'category',
        'dynamics',
        'treeViewMode',
        'organization',
        'members',
        'groupName',
        'description',
      ],
      ModuleKeywords.Responsibility
    );
  }
  getResponsibilityList(projectionFields: string[] = ['name', 'code']) {
    return this.search<ResponsibilityFieldDto>(
      { all: true },
      {
        projectionFields: projectionFields,
        filters: [
          // { "property": "category", "operation": "EQUAL", "value": Entity.CategoryEnum.Responsibilities }
        ],
      },
      { showLoading: false, showMsg: false }
    ) as any as Observable<ResponsibilityFieldDto[]>;
  }
  getMyResponsibilities(username: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseListResponse<ResponsibilityFieldDto>>(
      this.url + `${this.getMyResponsibilitiesUrl}${username}`,
      options
    ) as Observable<BaseListResponse<ResponsibilityFieldDto>>;
  }
  prepareResponsibility(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData(`${this.url}/${code}${this.prepareResponsibilityUrl}`, null, options);
  }
}
interface ResponsibilityEvents {}
