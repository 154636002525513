import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ComplianceReportResultDto, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class ComplianceReportResultDataService extends BaseRequestControllerService<ComplianceReportResultDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/compliance-report-result',
      [
        'id',
        'code',
        // 'tags',
        // 'tagsCodes',
        'name',
        'description',
        'date',
        'items',
        'targetCode',
        'control',
        'reportCode',
        'frequency',
        'policy',
        'complianceReportResults',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
      ],
      ModuleKeywords.ComplianceReportResult
    );
  }
}
