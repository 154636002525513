import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { SlaDto, ModuleKeywords} from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class SlaDataService extends BaseRequestControllerService<SlaDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.tprm, '/slas', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
    ],ModuleKeywords.Sla);
  }
}
