import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  ApiResponseBoolean,
  ApiResponseRiskProjectEvent,
  AssignRisksDto,
  ModuleKeywords,
  RequestHandlerOptions,
  RiskProject,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RiskProjectDataService extends BaseRequestControllerWithRuleService<
  RiskProject,
  ApiResponseRiskProjectEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.risk,
      '/riskProject',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
        'type',
        'status',
        'riskStatement',
        'riskAssessment',
        'category',
        'owner',
        'approver',
        'methodology',
      ],
      ModuleKeywords.RiskProject
    );
  }
  createProject(body: RiskProject, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<RiskProject>(`${this.url}/saveFromFlow`, body, options) as Observable<RiskProject>;
  }
  sendAssessment(projectId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<RiskProject>(
      `${this.url}/sendAssessment/${projectId}`,
      options
    ) as Observable<ApiResponseBoolean>;
  }
  // getRuleHandlers(
  //     idOrCode:string,
  //     options: RequestHandlerOptions = new RequestHandlerOptions()
  // ) {
  //     return this.dataService.getData<RiskProjectEvents.RiskProjectEventsEnum[]>(`${this.url}/get-rule-handlers/${idOrCode}`,options) as Observable<RiskProjectEvents.RiskProjectEventsEnum[]>;
  // }
  // changeStatus(
  //     id:string,
  //     status:string,
  //     options: RequestHandlerOptions = new RequestHandlerOptions()
  // ) {
  //     return this.dataService.postData<RiskProject>(`${this.url}/changeStatus/${id}/${status}`,null,options) as Observable<APIResponseBoolean>;
  // }
  assignRiskItems(
    projectId: string,
    entityId: string,
    body: AssignRisksDto,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<RiskProject>(
      `${this.url}/${projectId}/${entityId}`,
      body,
      options
    ) as Observable<ApiResponseBoolean>;
  }
}
