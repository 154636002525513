/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseTPVendorEvent { 
    status?: number;
    data?: ApiResponseTPVendorEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseTPVendorEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_REVIEW' | 'TO_WAITING_APPROVAL' | 'TO_APPROVED' | 'TO_REJECTED' | 'TO_ASSESSMENT' | 'TO_MONITOR' | 'TO_TERMINATED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Review: 'TO_REVIEW' as DataEnum,
        WaitingApproval: 'TO_WAITING_APPROVAL' as DataEnum,
        Approved: 'TO_APPROVED' as DataEnum,
        Rejected: 'TO_REJECTED' as DataEnum,
        Assessment: 'TO_ASSESSMENT' as DataEnum,
        Monitor: 'TO_MONITOR' as DataEnum,
        Terminated: 'TO_TERMINATED' as DataEnum
    };
}


