<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="mode == 'currency' ? currencyDataType : dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span
      class="w-full flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
      [class.p-float-label]="floatLabel"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
      <!-- tabindex for buttons is not published yet -->
      <div class="w-full flex flex-row border-noround-right">
        @if (!fControl?.disabled && mode == 'currency') {
          <button
            (click)="currencyOP.toggle($event)"
            type="button"
            pButton
            class="white-space-nowrap border-noround-right w-8rem"
          >
            <div>
              <span [class]="'currency-flag currency-flag-' + (currency | lowercase)"></span>
              {{ currency }}
              <span class="p-button-icon pi pi-angle-down"></span>
            </div>
          </button>
        }
        <p-inputNumber
          [inputStyleClass]="'w-full ' + (mode == 'currency' ? 'border-noround-left ' : '')"
          class="w-full"
          [inputId]="name"
          [(ngModel)]="numberValue"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onChangeNumberValue($event)"
          [mode]="mode"
          [showButtons]="showButtons"
          [maxFractionDigits]="maxFractionDigits"
          [buttonLayout]="buttonLayout"
          [incrementButtonClass]="incrementButtonClass"
          [incrementButtonIcon]="incrementButtonIcon"
          [decrementButtonClass]="decrementButtonClass"
          [decrementButtonIcon]="decrementButtonIcon"
          [min]="min"
          [max]="max"
          [placeholder]="placeholder"
          [class.ng-invalid]="fControl?.invalid"
          [class.ng-dirty]="fControl?.dirty"
          [class.p-disabled]="fControl?.disabled"
          [currency]="currency"
          [locale]="locale && locale.startsWith('ar') ? 'en-US' : locale"
        ></p-inputNumber>
      </div>
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('pattern')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (control?.hasError('minlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('maxlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('min')) {
        <small class="p-error p-invalid">
          {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
        </small>
      }
      @if (control?.hasError('max')) {
        <small class="p-error p-invalid">
          {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
        </small>
      }
      @if (customError) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
</app-input-view-switch>
<p-overlayPanel #currencyOP [showCloseIcon]="true">
  <ng-template pTemplate>
    <!-- <p-menu [style]="{ width: '15rem',height:'25rem',overflow:'auto' }" *ngIf="currencyList" [model]="currencyList"></p-menu> -->
    <p-listbox
      [options]="currencyList"
      [checkbox]="false"
      [filter]="true"
      [multiple]="false"
      optionLabel="label"
      [optionValue]="undefined"
      [style]="{ width: '15rem', height: '20rem', overflow: 'auto' }"
      [(ngModel)]="currencyValue"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onChangeCurrency($event, currencyOP)"
    >
      <ng-template let-item pTemplate="item">
        <div>
          <span [class]="'currency-flag currency-flag-' + item.codeLowerCase"></span>
          {{ item.code }}
        </div>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
