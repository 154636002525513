import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Client, ClientRole, ModuleKeywords, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleDataService extends BaseRequestControllerService<ClientRole> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.user, '/roles', ['id', 'name'], ModuleKeywords.Role);
  }

  getRoleByName(roleName: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<ClientRole>(
      this.url + '/role/name',
      { roleName: roleName },
      options
    ) as Observable<ClientRole>;
  }
  updateRoleByName(roleName: string, body: ClientRole, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.putData<ClientRole>(
      this.url + '/role/' + roleName,
      body,
      options
    ) as Observable<ClientRole>;
  }
  attachRoles(roleName: string, roles: string[], options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<ClientRole>(
      this.url + '/' + 'attach-roles',
      { role: roleName, roles: roles },
      options
    ) as Observable<any>;
  }
  createRole(body: ClientRole, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<ClientRole>(this.url + '/role', body, options) as Observable<ClientRole>;
  }
  getClientRoleList(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<ClientRole[]>(this.url + '/roles', options) as Observable<ClientRole[]>;
  }
  getClientList(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<Client[]>(this.url + '/clients', options) as Observable<Client[]>;
  }
}
