import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  AppPermissions,
  Control,
  ControlGroupDto,
  DataTypeEnum,
  IFieldDefinition,
  TargetTypeEnum,
  getEnumOptions,
  getEnumOptionsString,
} from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { SuggestionInputComponent } from '@shared/components/ui/input-fields/suggestion-input/suggestion-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';
import { ControlComplianceProgressBarComponent } from 'app/modules/org-framework/policy/policy-progress-bar/policy-progress-bar.component';

@Injectable({
  providedIn: 'root',
})
export class ControlGroupMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the asset',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'RES-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with RES-',
              },
            },
          ],
        },
      },
      calculationFunction: (params) => {
        return { name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' };
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Owner',
          name: 'owner',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    // {
    //   name: "Policy",
    //   key: "policy",
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicy,
    //   description: "Policy Linked to the control Group",
    //   tableDefinition: {
    //     filter: {
    //       type: 'recordCode',
    //       matchMode: "in",
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       recordCodeType: TargetTypeEnum.Policy
    //     },
    //   }
    // },
    // {
    //   name: "Section",
    //   key: "controlObjective",
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicyBodySection,
    //   description: "Policy Section Linked to the control objective",
    //   tableDefinition: {
    //     filter: {
    //       type: 'recordCode',
    //       matchMode: "in",
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       recordCodeType: TargetTypeEnum.ControlObjective
    //     },
    //   }
    // },
    {
      name: 'Respondent',
      key: 'attestationRespondent',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Respondent of the control objective changes',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'RES-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with RES-',
              },
            },
          ],
        },
      },
      calculationFunction: (params) => {
        return { name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' };
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Compliance Status',
      key: 'progress',
      dataType: DataTypeEnum.DynamicComponent,
      required: false,
      showInTable: true,
      description: 'Compliance Status of the control objective respondents',
      tableDefinition: {
        options: {
          dynamicComponent: ControlComplianceProgressBarComponent,
        },
        passRowAsData: true,
      },
    },
    {
      name: 'Controls Number',
      key: 'controlsNumber',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      description: 'Number of Controls',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      useInBulk: true,
      formField: {
        componentType: NumberInputComponent,
        options: {
          label: 'Controls Number',
          name: 'controlsNumber',
          control: new FormControl(null),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,

        },
      },
    },
    {
      name: 'Control Frequency',
      key: 'controlFrequency',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      description: 'Control Frequency of the control group',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      useInBulk: true,
      formField: {
        componentType: SuggestionInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Ex: 7 days',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: [
                { label: 'Yearly', value: 365 },
                { label: 'Quarterly', value: 90 },
                { label: 'Monthly', value: 30 },
                { label: 'Weekly', value: 7 },
                { label: 'Daily', value: 1 },
              ],
              optionLabel: 'name'
            },
            numberInput: {
              min: 0,
              maxFractionDigits: 0,

            },
          },
        },
      },
    },
    {
      name: 'Control Classification',
      key: 'controlClassification',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Control Classification of the control group',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ControlGroupDto.ControlClassificationEnum,
        },
      },
      importerDefinition: {
        type: 'dropdown',
        field: {
          validators: [
            {
              key: 'dropdown',
              value: {
                type: 'dropdown',
                type2: null,
                value1: getEnumOptionsString(ControlGroupDto.ControlClassificationEnum),
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: false,
                hintText: '',
              },
            },
          ],
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(Control.ControlClassificationEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Entities',
      key: 'controlEntities',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadAnyEntity,
      description: 'Entities Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: [TargetTypeEnum.Organization, TargetTypeEnum.Responsibility, TargetTypeEnum.Objective, TargetTypeEnum.Asset, TargetTypeEnum.Event, TargetTypeEnum.Geography, TargetTypeEnum.Process,],
          multi: true
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Control Objectives',
      key: 'controlObjectives',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadControlObjective,
      description: 'Control Objectives Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Questionnaire',
      key: 'controlAttestationTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadQuestionnaire,
      description: 'Questionnaire Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'QSR-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with QSR-',
              },
            },
          ],
        },
      },
    },
    {
      name: 'Standards',
      key: 'standards',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadStandard,
      description: 'Standard Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Standard,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Standards',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['STANDARD'],
            },
            dropDownInput: {
              multi: true,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Guidelines',
      key: 'guidelines',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadGuideline,
      description: 'Guidelines to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Guideline,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Guidelines',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['GUIDELINE'],
            },
            dropDownInput: {
              multi: true,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Entities',
      key: 'controlEntities',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      // permissions: AppPermissions.ReadGroup,
      description: 'Entities of the Record',
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Entities',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION'],
            },
            dropDownInput: {
              multi: true,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Applicable To',
      key: 'applicableTo',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      // permissions: AppPermissions.ReadGroup,
      description: 'Entities of the Record',
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Type',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['ENTITY_TYPE'],
            },
            dropDownInput: {
              multi: true,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
