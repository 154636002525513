/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ControlEntityDto { 
    code?: string;
    controlClassification?: ControlEntityDto.ControlClassificationEnum;
    controlStatus?: ControlEntityDto.ControlStatusEnum;
    controlComplianceStatus?: ControlEntityDto.ControlComplianceStatusEnum;
}
export namespace ControlEntityDto {
    export type ControlClassificationEnum = 'DETECTIVE' | 'CORRECTIVE' | 'PREVENTIVE' | 'DETERRENT' | 'COMPENSATING' | 'RECOVERY' | 'DIRECTIVE' | 'OTHER';
    export const ControlClassificationEnum = {
        Detective: 'DETECTIVE' as ControlClassificationEnum,
        Corrective: 'CORRECTIVE' as ControlClassificationEnum,
        Preventive: 'PREVENTIVE' as ControlClassificationEnum,
        Deterrent: 'DETERRENT' as ControlClassificationEnum,
        Compensating: 'COMPENSATING' as ControlClassificationEnum,
        Recovery: 'RECOVERY' as ControlClassificationEnum,
        Directive: 'DIRECTIVE' as ControlClassificationEnum,
        Other: 'OTHER' as ControlClassificationEnum
    };
    export type ControlStatusEnum = 'DRAFT' | 'ATTEST' | 'EXCEPTED' | 'MONITOR' | 'RETIRED';
    export const ControlStatusEnum = {
        Draft: 'DRAFT' as ControlStatusEnum,
        Attest: 'ATTEST' as ControlStatusEnum,
        Excepted: 'EXCEPTED' as ControlStatusEnum,
        Monitor: 'MONITOR' as ControlStatusEnum,
        Retired: 'RETIRED' as ControlStatusEnum
    };
    export type ControlComplianceStatusEnum = 'COMPLIANT' | 'NON_COMPLIANT' | 'NO_RESPONSE';
    export const ControlComplianceStatusEnum = {
        Compliant: 'COMPLIANT' as ControlComplianceStatusEnum,
        NonCompliant: 'NON_COMPLIANT' as ControlComplianceStatusEnum,
        NoResponse: 'NO_RESPONSE' as ControlComplianceStatusEnum
    };
}


