import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { GlobalValueListItem, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GlobalValueListItemDataService extends BaseRequestControllerService<GlobalValueListItem> {
  constructor(
    dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.dataStructure,
      '/global-value-list-items',
      [
        'creatorName',
        'creationDate',
        'lastModifierName',
        'lastModificationDate',
        'id',
        'searchScore',
        'recordStatus',
        'uuid',
        'description',
        'code',
        'label',
        'tags',
        'tagsCodes',
        'parent',
        'parents',
        'module',
        'systemGenerated',
        'originalLanguage',
        'translations',
        'systemLocked',
        'softDeleteDate',
        'numericValue',
        'value',
        'color',
        'icon',
        'helpText',
        'order',
        'displayOrder',
        'codePrefix',
        'lockedUntil',
        'lockedForEdit',
        'lockedBy',
        'userAccessLevel',
      ],
      ModuleKeywords.GlobalValueListItem
    );
  }
}
