import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleKeywords, QuestionnaireAnswerDto } from '@shared/classes';
import { BaseRequestControllerService } from 'app/shared/services/api/base-request-controller.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestionsValueDataService extends BaseRequestControllerService<QuestionnaireAnswerDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.questions,
      '/questionnaireAnswers',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'name',
        'questionnaire',
        'questionnaireType',
        'questionnaireName',
        'type',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'scoreStatus',
        'scoringType'
      ],
      ModuleKeywords.QuestionnaireValue
    );
  }

  navigateToListPage() {
    this.router.url.lastIndexOf('/my/') > -1
      ? this.navigateToMyQuestionnaireListPage()
      : this.navigateToQuestionnaireListPage();
  }

  navigateToQuestionnaireListPage() {
    this.router.navigateByUrl(`question-management/questionnaire-value/list`);
  }

  navigateToMyQuestionnaireListPage() {
    this.router.navigateByUrl(`question-management/questionnaire-value/my/list`);
  }
}
