import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ModuleKeywords } from '@shared/classes';
import { ApiResponseAuditCaseEvent, AuditCase } from '@shared/classes/model/backend/audit';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';

import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuditCaseDataService extends BaseRequestControllerWithRuleService<
  AuditCase,
  ApiResponseAuditCaseEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.audit,
      '/audit-cases',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'description',
        'name',
        'label',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'state',
        'controlClassification',
        'auditCaseStatus',
        'auditProject',
        'entities',
        'respondent',
        'auditQuestionnaire',
        'evidenceQuestionnaire',
        'auditor',
        'auditCaseTemplate',
        'plannedDuration',
        'plannedEndDate',
        'plannedStartDate',
        'owner',
        'controls',
        'control',
        'entity',
        'auditProgram',
      ],
      ModuleKeywords.AuditCase
    );
  }
}
