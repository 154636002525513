import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SubSink } from './sub-sink';

@Directive()
export abstract class Base implements OnDestroy {
  subs = new SubSink();

  /**
   * Observable for takeUntil usage
   *
   * @type {Subject<boolean>}
   * @memberof UnsubscribeOnDestroyAdapter
   */
  destroy$: Subject<boolean> = new Subject();
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destorySubject();
    this.onDestroy();
  }
  destorySubject() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
  onDestroy() { }
}
