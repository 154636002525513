import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ControlsGapReportResultDto, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ControlsGapReportResultDataService extends BaseRequestControllerService<ControlsGapReportResultDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/controls-gaps-reports-results',
      [
        'id',
        'code',
        // 'tags',
        // 'tagsCodes',
        'name',
        'description',
        'date',
        'items',
        'targetCode',
        'control',
        'reportCode',
        'frequency',
        'policy',
        'complianceReportResults',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
      ],
      ModuleKeywords.ControlsGapReportResult
    );
  }
}
