import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { environment } from '@env/environment';
import {
  ApiResponseBoolean,
  FilterItem,
  ModuleKeywords,
  NotificationDto,
  RequestHandlerOptions,
} from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyNotificationsDataService extends BaseRequestControllerService<NotificationDto> {
  private markAllAsSeenApi: string = '/markAllAsSeen';
  private markAsSeenApi: string = '/seen';
  private markAsSeenListApi: string = '/seen/list';
  constructor(
    private dataService: DataService,
    private router: Router,
    private jwtTokenService: JWTTokenService
  ) {
    super(
      dataService,
      environment.userOperations,
      '/my-notifications',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'lockedUntil',
        'target',
        'targetId',
        'user',
        'targetCode',
        'autoSubscription',
        'notificationType',
        'subscriptionType',
        'sender',
        'seenBy',
        'data',
        'relatedToCode',
        'relatedToType',
      ],
      ModuleKeywords.MyNotification
    );
  }
  getNotifications(page = 0, showAll = false, defaultDateOffset = 30) {
    const today = new Date();
    const dateFilterStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - defaultDateOffset);
    let dateFilter: FilterItem = {
      property: 'creationDate',
      value: dateFilterStart,
      operation: 'GREATER_THAN_OR_EQUAL_TO',
      typeShape: 'DATETIME',
    };
    let filters: FilterItem[] = showAll
      ? [dateFilter]
      : [{ property: 'seenBy', operation: 'NOT_IN', value: [this.jwtTokenService.getPreferredUsername()] }, dateFilter];
    return this.search(
      { page: page, size: 30, all: false, sort: ['creationDate,desc'] },
      { filters: filters },
      { showLoading: false, showMsg: false }
    );
    // return this.getList({showMsg:true,showLoading:false,params:new PageableOptions()},'');
  }
  markAllAsSeen(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<ApiResponseBoolean>(
      `${this.url}${this.markAllAsSeenApi}`,
      null,
      options
    ) as Observable<ApiResponseBoolean>;
  }
  markAsSeen(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<ApiResponseBoolean>(
      `${this.url}/${idOrCode}${this.markAsSeenApi}`,
      null,
      options
    ) as Observable<ApiResponseBoolean>;
  }
  markAsSeenList(codeList: string[], options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<ApiResponseBoolean>(
      `${this.url}/${this.markAsSeenApi}`,
      codeList,
      options
    ) as Observable<ApiResponseBoolean>;
  }
}
