import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  ApiResponseStandardEvent,
  BaseResponse,
  ModuleKeywords,
  RequestHandlerOptions,
  Standard,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class StandardDataService extends BaseRequestControllerWithRuleService<
  Standard,
  ApiResponseStandardEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/standards',
      [
        'creatorName',
        'creationDate',
        'lastModifierName',
        'lastModificationDate',
        'id',
        'tags',
        'tagsCodes',
        'searchScore',
        'recordStatus',
        'translations',
        'originalLanguage',
        'uuid',
        'description',
        'code',
        'label',
        'parent',
        'parents',
        'module',
        'systemGenerated',
        'softDeleteDate',
        'name',
        'owner',
        'status',
        'approvers',
        'reviewers',
        'sections',
        'policySections',
        'citations',
        'state',
        'codePrefix',
        'lockedUntil',
        'lockedForEdit',
        'lockedBy',
        'userAccessLevel',
        'reviewedBy',
        'publishedBy',
      ],
      ModuleKeywords.Standard
    );
  }
  // public getApprove(policyIdOrCode) {
  //   const newLocal = this.url + `/${policyIdOrCode}/approve`;
  //   return this.dataService.getData<BaseResponse<PolicyBody>>(
  //     newLocal,
  //     this.apiOptions
  //   ) as Observable<BaseResponse<any>>;
  // }

  public getNoFurtherComment(idOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${idOrCode}/no-further-comment/${mode}`;
    return this.dataService.getData<BaseResponse<Standard>>(newLocal, options) as Observable<BaseResponse<any>>;
  }

  public getApprove(policyIdOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${policyIdOrCode}/approve/${mode}`;
    return this.dataService.getData<BaseResponse<Standard>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
}
