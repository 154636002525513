import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ModuleKeywords, ReportSchedulerDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportSchedulerDataService extends BaseRequestControllerService<ReportSchedulerDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.dataStructure,
      '/report-schedulers',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
        'filter',
        'report',
        'frequency',
        'lastRunDate',
      ],
      ModuleKeywords.ReportScheduler
    );
  }
  runReport<T = ReportSchedulerDto>(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(`${this.url}/run/${code}`, options) as Observable<BaseResponse<T>>;
  }
}
