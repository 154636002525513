import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { EmailTemplateDto, ModuleKeywords, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesDataService extends BaseRequestControllerService<EmailTemplateDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.userOperations,
      '/email-templates',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'label',
        'templateType',
        'applyTo',
        'systemGenerated',
        'isDefault',
        'subjectTemplate',
      ],
      ModuleKeywords.EmailTemplate
    );
  }
  getContextValues(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData(`${this.url}/available-context-values`, options) as Observable<string[]>;
  }
}
