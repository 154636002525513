import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ModuleKeywords, ProgramRelatedDetailsDto, RequestHandlerOptions } from '@shared/classes';
import { ApiResponseAuditProgramEvent, AuditProgram } from '@shared/classes/model/backend/audit';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';

import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditProgramDataService extends BaseRequestControllerWithRuleService<
  AuditProgram,
  ApiResponseAuditProgramEvent.DataEnum
> {
  myProgramManagerSearchUrl = '/my-program-manager';
  programChartUrl = '/chart';

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.audit,
      '/audit-programs',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'description',
        'name',
        'label',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'auditProgramStatus',
        'approver',
        'reviewers',
        'owner',
        'startDate',
        'endDate',
        'auditUniverse',
        'plannedExpenses',
        'plannedResources',
        'expensesVariance',
        'budgetedExpenses',
        'budgetedResources',
        'resourcesVariance',
        'actualResources',
        'manager',
        'type',
      ],
      ModuleKeywords.AuditProgram
    );
  }
  getChartData(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<ProgramRelatedDetailsDto>>(
      this.url + '/' + idOrCode + this.programChartUrl,
      options
    ) as Observable<BaseResponse<ProgramRelatedDetailsDto>>;
  }
}
