import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ModuleKeywords, PolicyBody, PolicyBodySectionDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolicyBodySectionDataService extends BaseRequestControllerService<PolicyBodySectionDto> {
  lastApprovedUrl = '/search-last-approved';
  unLinkingString = 'sections';
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.framework,
      '/policy-body-section',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'owner',
        'name',
        'validFrom',
        'validTo',
        'description',
        'policyBody',
        'externalReferences',
        'exceptionDuration',
        'allowExceptions',
        'reviewers',
        'approvers',
        'approvedBy',
        'policyStatus',
        'reviewedBy',
        'body',
        'order',
        'displayOrder',
        'subject',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'parent',
        'lastModificationDate',
        'type',
      ],
      ModuleKeywords.PolicyBodySection
    );
  }
  public getNoFurtherComment(policyIdOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${policyIdOrCode}/no-further-comment/${mode}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
  public getApprove(policyIdOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${policyIdOrCode}/approve/${mode}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
  linkControlObjectives(
    code: string,
    body: { link: string[]; unLink: string[]; addAndLink: string[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<BaseResponse<any>>(this.url + '/' + code + '/link', body, options) as Observable<
      BaseResponse<any>
    >;
  }
}
