import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { MetricDto, ModuleKeywords } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class MetricDataService extends BaseRequestControllerService<MetricDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.performance,
      '/metrics',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
        'qsr',
        'name',
        'category',
        'type',
        'frequency',
        'status',
        'target',
        'priority',
        'startDate',
        'owner',
        'reporter',
        'calculationType',
        'campaignScore',
        'currentValue',
        'minValue',
        'maxValue',
        'orangeThreshold',
        'redThreshold',
        'classification',
        'aggregateFrom',
        'aggregationQuestion',
      ],
      ModuleKeywords.Metric
    );
  }
}
